/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react'
import { Menu, Button } from 'semantic-ui-react'
import { useQuery } from '@/utils/hooks/useQuery'
import { IconArrowsMaximize } from '@tabler/icons-react'

import { useVideoPlayer } from './VideoPlayerContext'

const VideoPlayer = ({ sources = [], onPlaying, onPause, onSeeked }) => {
  const { videoRef, toggleFullScreen, isVideoVisible } = useVideoPlayer()
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0)
  const query = useQuery()

  useEffect(() => {
    if (!videoRef.current) {
      return
    }

    const { currentTime = 0, playbackRate = 1, paused = true } = videoRef.current

    videoRef.current.load()

    videoRef.current.onloadeddata = () => {
      const timestamp = query.get('timestamp')
      videoRef.current.currentTime = timestamp || currentTime
      videoRef.current.playbackRate = playbackRate

      if (!paused) {
        videoRef.current.play()
      }
    }
  }, [currentVideoIndex, videoRef])

  return (
    <div>
      {sources.length > 0 ? (
        <div className={isVideoVisible ? '' : 'hidden-video-player'}>
          <div className="video-player-header">
            <Menu pointing secondary className="tab-navigation video-tabs">
              {sources.map((_, index) => (
                <Menu.Item
                  key={index}
                  data-testid={`video-player-tab-${index}`}
                  active={currentVideoIndex === index}
                  onClick={() => setCurrentVideoIndex(index)}
                >
                  <span>Screen {index + 1}</span>
                </Menu.Item>
              ))}
            </Menu>
            <Button className="svg-button" secondary icon onClick={toggleFullScreen}>
              <IconArrowsMaximize />
              Full Screen
            </Button>
          </div>
          <video
            className="call-explorer-video"
            ref={videoRef}
            onSeeked={onSeeked}
            onPlaying={onPlaying}
            onPause={onPause}
          >
            <source src={sources[currentVideoIndex]} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default VideoPlayer
