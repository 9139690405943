import React, { useState } from 'react'
import { Button, Segment } from 'semantic-ui-react'
import {
  IconArrowLeft,
  IconChartDonut4,
  IconChartBar,
  IconThumbUp,
  IconThumbDown,
  IconArrowUpRight,
} from '@tabler/icons-react'

import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { ModuleCard } from '@/components/cards/ModuleCard'
import { EChart } from '@/components/charts/EChart'
import { Pill } from '@/components/pills/Pill'
import { SegmentedControl } from '@/components/buttons/SegmentedControl'

// TODO: Make this into a Provider/Consumer pattern
export const InsightsModule = ({
  option,
  drillDownOption,
  handleGoBack,
  handleDrillDown,
  showTable,
}) => {
  const [view, setView] = useState('chart')

  return (
    <ModuleCard
      className="insights-card"
      compact
      title={
        <div className="flex-align-center medium-gap">
          {drillDownOption ? (
            <>
              <Button compact icon secondary onClick={handleGoBack} className="svg-button">
                <IconArrowLeft />
                Back
              </Button>
              <div
                className="color-block"
                style={{ backgroundColor: drillDownOption.labelColor }}
              />
              <div>{drillDownOption.label}</div>
            </>
          ) : (
            <div>{option.label}</div>
          )}
        </div>
      }
      pills={
        !drillDownOption && (
          <Pill bold small emphasized style={{ textTransform: 'uppercase' }}>
            Last 30 Days
          </Pill>
        )
      }
      buttons={
        <div className="flex-align-stretch small-gap">
          <SegmentedControl
            items={[
              {
                accessor: 'donut',
                icon: <IconChartDonut4 />,
                active: view === 'donut',
                onClick: () => setView('donut'),
              },
              {
                accessor: 'chart',
                icon: <IconChartBar />,
                active: view === 'chart',
                onClick: () => setView('chart'),
              },
            ]}
          />
          <Button className="svg-button" icon basic>
            Settings
          </Button>
        </div>
      }
    >
      <div className="flex-align-center medium-gap">
        <EChart option={drillDownOption || option} events={{ click: handleDrillDown }} />
        {showTable && (
          <Segment className="not-padded" style={{ width: '100%' }}>
            <AdvancedTable
              striped={false}
              rows={(drillDownOption || option).series.data.map((item) => ({
                reasons: {
                  as: (
                    <div className="flex-align-center medium-gap">
                      <div className="color-block" style={{ backgroundColor: item.color }} />
                      <div>{item.name}</div>
                    </div>
                  ),
                  value: item.name,
                },
                percent: {
                  as: (
                    <div className="flex-align-center flex-end small-gap">
                      {`${item.value}%`}
                      <IconArrowUpRight className="icon-svg muted-text" />
                    </div>
                  ),
                  value: item.value,
                },
              }))}
              columns={[
                { accessor: 'reasons', label: 'Reasons' },
                { accessor: 'percent', label: 'Percent of Total', headerAlignment: 'right' },
              ]}
              defaultOrder="desc"
              defaultOrderBy="percent"
            />
          </Segment>
        )}
      </div>
      <div className="flex-align-center flex-space-between" style={{ marginTop: '1.5rem' }}>
        <small className="muted-text">
          This insight is based on data from 23k calls recorded in the last 30 days.
        </small>
        <div className="flex-align-center medium-gap">
          <small className="muted-text">Did you find the insight data helpful?</small>
          <div className="flex-align-center">
            <Button basic icon className="svg-button" compact>
              <IconThumbUp />
            </Button>
            <Button basic icon className="svg-button" compact>
              <IconThumbDown />
            </Button>
          </div>
        </div>
      </div>
    </ModuleCard>
  )
}
