import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { IconMoodLookDown } from '@tabler/icons-react'

const FourOhFour = () => {
  return (
    <>
      <Helmet>
        <title>Balto Cloud | Not Found</title>
      </Helmet>

      <div className="vertical-wrapper alternate-background" style={{ marginTop: '-50px' }}>
        <div className="text-center">
          <div>
            <IconMoodLookDown
              style={{ height: '35px', width: '35px', color: `var(--color-icon-high-contrast)` }}
            />
          </div>
          <h2 data-testid="404-page" style={{ fontWeight: '600', marginTop: '0' }}>
            Page not found
          </h2>

          <p style={{ marginBottom: '2rem' }}>
            The page you are looking for does not exist or has been moved.
          </p>

          <Link className="ui button primary" to="/">
            Return Home
          </Link>
        </div>
      </div>
    </>
  )
}

export default FourOhFour
