import { useRef, useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

export const useTooltip = () => {
  const tooltipRef = useRef(null)
  const [tooltipActive, setTooltipActive] = useState(false)
  const [tooltipContent, setTooltipContent] = useState('')
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 })

  const handleMouseEnter = () => {
    setTooltipActive(true)
  }

  const handleMouseLeave = () => {
    setTooltipActive(false)
    setTooltipContent('')
  }

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event

    if (!event.target.classList.contains('matrix-item')) {
      setTooltipContent('')
      setTooltipActive(false)
    } else {
      setTooltipActive(true)

      const tooltipWidth = tooltipRef.current?.offsetWidth || 0
      const tooltipHeight = tooltipRef.current?.offsetHeight || 0
      const viewportWidth = window.innerWidth
      const viewportHeight = window.innerHeight

      let tooltipX = clientX + 12
      let tooltipY = clientY + 12

      // Check if tooltip exceeds the right side of the viewport
      if (tooltipX + tooltipWidth > viewportWidth) {
        tooltipX = clientX - tooltipWidth - 12
      }

      // Check if tooltip exceeds the bottom of the viewport
      if (tooltipY + tooltipHeight > viewportHeight) {
        tooltipY = viewportHeight - tooltipHeight - 12
      }

      // Extract tooltip content from the data attributes
      // This is kind of hacky...
      const content = event.target.dataset

      if (!isEmpty(content)) {
        setTooltipContent(content)
        setTooltipPosition({ x: tooltipX, y: tooltipY })
      }
    }
  }

  useEffect(() => {
    const handleRemoveTooltipOnScroll = () => {
      setTooltipActive(false)
      setTooltipContent('')
    }

    window.addEventListener('scroll', handleRemoveTooltipOnScroll)

    return () => {
      window.removeEventListener('scroll', handleRemoveTooltipOnScroll)
    }
  }, [])

  return {
    tooltipRef,
    tooltipActive,
    tooltipContent,
    tooltipPosition,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseMove,
  }
}
