import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Input, Checkbox, Button } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import moment from 'moment'
import classNames from 'classnames'

import { setFilter, setDateRangeFilter, clearOrgFilter } from '@/reducers/analytics/analytics.redux'
import {
  fetchAllData,
  fetchCategoriesByOrgSectionAndPlaybooks,
  applyFilters,
} from '@/reducers/analytics/analytics.actions'
import { MultiSelect } from '@/components/forms/MultiSelect'
import { getOrganizationOptions, getPlaceholderContent } from '@/utils/helpers'
import { customStaticRanges } from '@/components/helpers'
import { minCallDurationOptions } from '@/utils/constants'
import { ErrorMessage } from '@/components/forms/ErrorMessage'
import { OrganizationFilter } from '@/components/filters/OrganizationFilter'
import { MultiFilter } from '@/components/filters/MultiFilter'
import { WinsFilter } from '@/components/filters/WinsFilter'
import { DateRangePickerReusable } from '@/components/datePickers/DateRangePickerReusable'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'

import Select from '../../components/forms/Select'

export const AnalyticsFilters = () => {
  const dispatch = useDispatch()
  const [scrolled, setScrolled] = useState(false)
  const [agentSearchQuery, setAgentSearchQuery] = useState('')
  const [dateInvalid, setDateInvalid] = useState(false)
  const { section } = useParams()
  const { organizations } = useSelector((state) => state)
  const { organizationid: organizationId } = useSelector((state) => state.currentUser)
  const analyticsStore = useSelector((state) => state.analytics)
  const { data, filters, loading } = analyticsStore
  const isBaltoAdmin = organizationId === 1
  const callDurationInvalid =
    Boolean(filters.callDuration) &&
    Boolean(filters.maxCallDuration) &&
    Number(filters.callDuration) >= Number(filters.maxCallDuration)

  const organizationOptions = getOrganizationOptions(organizations)
  const originalSection = section === 'dynamic-prompt' ? 'deck' : section

  const handleFilterChange = (value) => {
    dispatch(setFilter(value))
  }

  const handleDateRangeFilterChange = (value) => {
    const duration = moment.duration(moment(value.endDate).diff(moment(value.startDate)))
    const days = duration.asDays()

    if (days > 62) {
      setDateInvalid(true)
    } else {
      setDateInvalid(false)
    }

    const dateRange = customStaticRanges.find((staticRange) => {
      const range = staticRange.range()

      if (value.startDate === range.startDate && value.endDate === range.endDate) {
        return staticRange.label
      }

      return ''
    })

    dispatch(setDateRangeFilter(value))
    dispatch(setFilter({ dateRange: dateRange ? dateRange.label.toLowerCase() : '' }))
  }

  const handlePlaybookFilterChange = (value) => {
    dispatch(setFilter({ playbooks: value, deckCategories: [], postcallCategories: [] }))
    dispatch(
      fetchCategoriesByOrgSectionAndPlaybooks(
        isBaltoAdmin ? filters.organization : organizationId,
        'deck',
        value
      )
    )
    dispatch(
      fetchCategoriesByOrgSectionAndPlaybooks(
        isBaltoAdmin ? filters.organization : organizationId,
        'postcall',
        value
      )
    )
  }

  const handleFilterSubmit = (section) => {
    // CSV uses its own export button instead of the filters button
    if (section !== 'csv') {
      dispatch(applyFilters(section))
    }
  }

  const handleOrgSelect = (option, action) => {
    dispatch(clearOrgFilter())

    if (action.action === 'clear') {
      handleFilterChange({ organization: '' })
    } else {
      const orgSelected = option.value

      handleFilterChange({ organization: orgSelected })
      dispatch(fetchAllData(orgSelected))
    }
  }

  const isLoading = (section) => {
    if (!section) {
      return loading.overviewPage
    }
    return loading[`${section}Page`]
  }

  const getDisabledReason = () => {
    // Disable button while loading
    if (isLoading(section)) {
      return 'Filters are loading...'
    }

    // Disable button if organization is not selected for admin
    if (isBaltoAdmin && !filters.organization) {
      return 'Select an organization'
    }

    // Disable button if date is over 62 days
    if (dateInvalid) {
      return 'Date is invalid'
    }

    // Max call duration is less than min call duration
    if (callDurationInvalid) {
      return 'Maximum call duration must be greater than minimum call duration'
    }

    return false
  }

  const handleScroll = () => {
    setScrolled(window.scrollY > 1)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const disabledReason = getDisabledReason()

  return (
    <>
      <Form
        data-testid="analytics-filters"
        className={classNames('filter-form', 'analytics-filter-form', { scrolled })}
        onSubmit={() => {
          handleFilterSubmit(originalSection)
        }}
      >
        <div>
          <div className="filter-grid">
            {isBaltoAdmin && (
              <OrganizationFilter
                options={organizationOptions}
                value={filters.organization}
                onChange={handleOrgSelect}
              />
            )}
            {section !== 'csv' && (
              <Form.Field>
                <label>Agents</label>
                <MultiSelect
                  isSelectAll
                  label="Agent"
                  options={!agentSearchQuery && data.agents.length > 1000 ? [] : data.agents}
                  optionsLength={data.agents.length}
                  loading={loading.agents}
                  value={filters.agents}
                  onInputChange={(inputValue) => {
                    setAgentSearchQuery(inputValue)
                  }}
                  onChange={(value) => {
                    handleFilterChange({ agents: value })
                  }}
                  noOptionsMessage={() =>
                    agentSearchQuery ? 'No results found' : 'Begin typing to search...'
                  }
                  fixedWidth
                  disabled={analyticsStore.loading.agents || isEmpty(analyticsStore.data.agents)}
                  {...getPlaceholderContent(analyticsStore.data, analyticsStore.loading, 'agents')}
                />
              </Form.Field>
            )}

            <MultiFilter
              label="Tags"
              accessor="tags"
              store={analyticsStore}
              handler={handleFilterChange}
            />

            <Form.Field data-testid="playbooks-multi-select">
              <label>Playbooks</label>
              <MultiSelect
                isSelectAll
                label="Playbook"
                options={analyticsStore.data.playbooks}
                optionsLength={
                  analyticsStore.data.playbooks ? analyticsStore.data.playbooks.length : 0
                }
                loading={analyticsStore.loading.playbooks}
                value={analyticsStore.filters.playbooks}
                onChange={(value) => handlePlaybookFilterChange(value)}
                fixedWidth
                disabled={
                  analyticsStore.loading.playbooks || isEmpty(analyticsStore.data.playbooks)
                }
                {...getPlaceholderContent(analyticsStore.data, analyticsStore.loading, 'playbooks')}
              />
            </Form.Field>

            {['deck', 'postcall'].includes(originalSection) && (
              <MultiFilter
                label="Categories"
                accessor={`${originalSection}Categories`}
                store={analyticsStore}
                handler={handleFilterChange}
              />
            )}

            <Form.Field>
              <label>Min. Call Duration</label>
              <Select
                allowFreeText
                isNumberField
                placeholder="Minutes per call"
                options={minCallDurationOptions}
                value={filters.callDuration}
                onChange={(option, action) => {
                  if (action.action === 'clear') {
                    handleFilterChange({ callDuration: '' })
                  } else {
                    handleFilterChange({ callDuration: option.value })
                  }
                }}
              />
            </Form.Field>

            {section !== 'csv' && (
              <Form.Field>
                <label>Max. Call Duration</label>
                <Input
                  type="number"
                  placeholder="Minutes per call"
                  value={filters.maxCallDuration}
                  onChange={(e) => {
                    handleFilterChange({ maxCallDuration: e.target.value || '' })
                  }}
                  error={callDurationInvalid}
                />
                {callDurationInvalid && <ErrorMessage content="Max must be greater than min" />}
              </Form.Field>
            )}

            <Form.Field>
              <label>Date Range</label>
              <DateRangePickerReusable
                data-testid="date-range-picker"
                startDate={filters.startDate}
                endDate={filters.endDate}
                onChange={({ selection }) => {
                  handleDateRangeFilterChange(selection)
                }}
                error={dateInvalid}
              />
              {dateInvalid && <ErrorMessage content="Date range must be two months or less" />}
            </Form.Field>
            {section !== 'csv' && section !== 'usage' && (
              <WinsFilter
                filters={filters}
                onChange={(option, action) => {
                  dispatch(setFilter({ isWin: action.action === 'clear' ? null : option.value }))
                }}
              />
            )}
            {!isEmpty(data.dispositions) && section !== 'csv' && section !== 'usage' && (
              <Form.Field>
                <MultiFilter
                  label="Dispositions"
                  accessor="dispositions"
                  store={analyticsStore}
                  handler={handleFilterChange}
                />
              </Form.Field>
            )}
          </div>
          <Form.Field>
            <Checkbox
              data-testid="include-managers-checkbox"
              label="Include Managers"
              checked={filters.includeManagers}
              onChange={(e, { checked }) => {
                handleFilterChange({ includeManagers: checked })
              }}
            />
          </Form.Field>
        </div>

        <div className="filter-buttons">
          {section !== 'csv' && (
            <Form.Field>
              <label className="visibility-hidden">&nbsp;</label>
              <div>
                <ConditionalTooltip
                  tooltipProps={{ position: 'bottom right' }}
                  condition={Boolean(disabledReason)}
                  content={disabledReason}
                >
                  <Button
                    fluid
                    primary
                    data-testid="filter-button"
                    type="submit"
                    loading={isLoading(section)}
                    disabled={Boolean(disabledReason)}
                  >
                    Filter
                  </Button>
                </ConditionalTooltip>
              </div>
            </Form.Field>
          )}
        </div>
      </Form>
    </>
  )
}
