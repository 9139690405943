import { formatCallDuration } from '@/utils/helpers'

import {
  ANALYTICS_FILTER_TYPE,
  CALL_EXPLORER_FILTER_TYPE,
  SENTIMENT_FILTER_TYPE,
  MY_TEAM_FILTER_TYPE,
} from './savedFilters.constants'

export const getMyTeamFilterAgents = (savedFilters = []) => {
  const savedFilter = savedFilters[0]

  if (savedFilter?.filters) {
    return savedFilter.filters?.agents
  }

  return null
}

// Take the existing filter from the API and convert it to the format that the form schema expects
// Only used for reporting at the moment
export const formatSavedFilterFormExistingValues = (savedFilter = {}) => {
  return {
    name: savedFilter.name,
    isDefault: savedFilter.is_default,
    filterType: savedFilter.filter_type,
    // Inner values
    agents: savedFilter.filters.agents || [],
    playbooks: savedFilter.filters.playbooks || [],
    tags: savedFilter.filters.tags || [],
    callDuration: savedFilter.filters.callDuration || '',
    maxCallDuration: savedFilter.filters.maxCallDuration || '',
    includeManagers: savedFilter.filters.includeManagers,
    deckCategories: savedFilter.filters.deckCategories || [],
    postcallCategories: savedFilter.filters.postcallCategories || [],
    dateRange: savedFilter.filters.dateRange,
  }
}

// Format the filters from the form into the format that the API expects
// `is_default` is a boolean that determines which filter is the default for a filterType.
// Unrelated, the analytics filter type is a string called 'default'.
// Need to manually define all these so nothing extra gets through
// This can probbaly be refactored but I'm not sure the best way to do it.
export const formatSavedFilters = (savedFilter = {}, organizationId, filterType) => ({
  name: savedFilter.name,
  is_default: savedFilter.isDefault,
  filter_type: filterType || savedFilter.filterType,
  filters: {
    // Sentiment only
    ...(filterType === SENTIMENT_FILTER_TYPE && {
      ignoreWeekends: savedFilter.ignoreWeekends,
      timePeriod: savedFilter.timePeriod,
    }),

    // My Team, Reporting and Call Explorer
    ...([MY_TEAM_FILTER_TYPE, ANALYTICS_FILTER_TYPE, CALL_EXPLORER_FILTER_TYPE].includes(
      filterType
    ) && {
      organization: savedFilter.organization || savedFilter.organizationId || organizationId,
      agents: savedFilter.agents,
      tags: savedFilter.tags,
    }),

    // Reporting and Call Explorer
    ...([ANALYTICS_FILTER_TYPE, CALL_EXPLORER_FILTER_TYPE].includes(filterType) && {
      playbooks: savedFilter.playbooks,
      callDuration: formatCallDuration(savedFilter.callDuration),
      maxCallDuration: savedFilter.maxCallDuration,
      dateRange: savedFilter.dateRange,
    }),

    // Reporting only
    ...(filterType === ANALYTICS_FILTER_TYPE && {
      includeManagers: savedFilter.includeManagers,
      deckCategories: savedFilter.deckCategories,
      postcallCategories: savedFilter.postcallCategories,
    }),

    // Call Explorer only
    ...(filterType === CALL_EXPLORER_FILTER_TYPE && {
      dispositions: savedFilter.dispositions,
      isWin: savedFilter.isWin, // TODO: need to add to reporting
      includeCallsWithoutAudio: savedFilter.includeCallsWithoutAudio,
      keywords: savedFilter.keywords,
      keywordOptions: savedFilter.keywordOptions,
      checklist: savedFilter.checklist,
      deck: savedFilter.deck,
      postcall: savedFilter.postcall,
      notifications: savedFilter.notifications,
    }),
  },
})
