import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import Helmet from 'react-helmet'
import { Segment } from 'semantic-ui-react'
import { IconBriefcase, IconPencil, IconTrash } from '@tabler/icons-react'

import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import { AlertFilters } from '@/views/RealtimeCoaching/Alerts/AlertFilters'
import {
  fetchAgentsByOrg,
  fetchManagersByOrg,
} from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.actions'
import {
  deleteAlertConfiguration,
  editAlertConfiguration,
  loadAlertConfigurations,
  loadPlaybookNamesAndCids,
} from '@/reducers/realtime/realtime.actions'
import { setFilter } from '@/reducers/realtime/realtimeCoaching/realtimeCoaching.redux'
import { BaltoAdminManagerFilter } from '@/views/RealtimeCoaching/components/filters/BaltoAdminManagerFilter'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { useHistory } from 'react-router-dom'
import { AlertHeader } from './AlertHeader'
import { EditAlertForm } from './EditAlertForm'
import ConfirmForm from '../../../components/forms/ConfirmForm'
import { createAlertTableRows } from './AlertsHelpers'
import '../realtimeCoaching.scss'

const AlertsPage = ({ flags }) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [editedAlert, setEditedAlert] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deletedAlert, setDeletedAlert] = useState(null)
  const [alertConfigs, setAlertConfigs] = useState([])
  const [isReadOnly, setIsReadOnly] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const organizationUsers = useSelector((state) => state.organizationUsers.users)
  const organizationTags = useSelector((state) => state.organizationTags)
  // TODO: why we have so many alertConfigurations defined?? can we reduce?
  const newAlertConfiguration = useSelector((state) => state.realtimeNewAlertConfiguration)
  const { alertConfigurations, loading } = useSelector(
    (state) => state.realtimeOrganizationAlertConfigurations
  )
  const { filters } = useSelector((state) => state.realtimeCoaching)
  const { organizationid } = useSelector((state) => state.currentUser)
  const { customBranding } = useSelector((state) => state.customBranding)
  const isBaltoAdmin = organizationid === 1
  const filteredAlertConfigs = alertConfigurations.filter(
    ({ users, active, created_at, modified_at, tags }) => {
      if (filters.alertStatus.length) {
        if (!filters.alertStatus.some((status) => status.value === 'active') && active) {
          return false
        }
        if (!filters.alertStatus.some((status) => status.value === 'deactivated') && !active) {
          return false
        }
      }
      if (filters.agents.length || filters.tags.length) {
        const filterAgentIds = filters.agents.map(({ value }) => value)
        const filterTagIds = filters.tags.map(({ value }) => value)
        if (
          !users.some((userId) => filterAgentIds.includes(userId)) &&
          !tags.some((tagId) => filterTagIds.includes(tagId))
        ) {
          return false
        }
      }
      if (filters.nullableDateRange.startDate && filters.nullableDateRange.endDate) {
        const alertCreated = moment(created_at)
        const alertLastModified = moment(modified_at)
        const filterStartDate = moment(filters.nullableDateRange.startDate)
        const filterEndDate = moment(filters.nullableDateRange.endDate)
        if (
          !alertCreated.isBetween(filterStartDate, filterEndDate, 'days', '[]') &&
          !alertLastModified.isBetween(filterStartDate, filterEndDate, 'days', '[]')
        ) {
          return false
        }
      }
      return true
    }
  )

  useEffect(() => {
    const managerId = filters.baltoAdminManager || null
    dispatch(loadAlertConfigurations({ userId: managerId }))
    dispatch(loadPlaybookNamesAndCids())
    dispatch({
      type: 'REFRESH_USERS_ALERTS',
    })
  }, [])

  useEffect(() => {
    setIsReadOnly(isBaltoAdmin ? filters.organization !== organizationid : false)
  }, [alertConfigurations, organizationid, filters.organization])

  const handleAudibleClick = (id) => {
    const [clickedAlert] = alertConfigurations.filter((alertConfig) => alertConfig.id === id)

    dispatch(
      editAlertConfiguration(
        {
          data: { ...clickedAlert, audible: !clickedAlert.audible },
        },
        `Audible notifications for ${clickedAlert.name} has been ${
          clickedAlert.audible ? 'disabled' : 'enabled'
        }`
      )
    )
  }

  const updateActiveState = (alertId) => {
    const [clickedAlert] = alertConfigurations.filter((alertConfig) => alertConfig.id === alertId)

    dispatch(
      editAlertConfiguration(
        {
          data: { ...clickedAlert, active: !clickedAlert.active },
        },
        `${clickedAlert.name} has been ${clickedAlert.active ? 'deactivated' : 'activated'}`
      )
    )
  }

  useEffect(() => {
    setAlertConfigs(
      createAlertTableRows(
        filteredAlertConfigs.filter((alertConfig) => alertConfig?.all_users === false),
        organizationTags,
        organizationUsers,
        newAlertConfiguration.configPaths,
        isReadOnly,
        handleAudibleClick,
        updateActiveState
      )
    )
  }, [
    alertConfigurations,
    organizationTags,
    organizationUsers,
    newAlertConfiguration.configPaths,
    isReadOnly,
    filters.alertStatus,
    filters.agents,
    filters.nullableDateRange.startDate,
    filters.nullableDateRange.endDate,
    filters.tags,
  ])

  const deleteAlert = ({ id, name }) => {
    dispatch(
      deleteAlertConfiguration({
        alertConfigurationId: id,
        alertName: name.value,
      })
    )
  }

  // Build columns with LD flag conditions
  // This can be combined to remove .push() when the flag is removed
  const columns = [
    {
      accessor: 'name',
      label: 'Alert Name',
      isSearchable: true,
      sticky: true,
    },
    {
      accessor: 'active_for',
      label: 'Status',
    },
    {
      accessor: 'created_at',
      label: 'Created On',
    },
  ]
  if (flags.rtcOrgLevelAlertsTableColumns2022) {
    columns.push({
      accessor: 'created_by',
      label: 'Created By',
    })
  }
  if (flags.rtcOrgLevelAlertsTableColumns2022) {
    columns.push({
      accessor: 'assigned_to',
      label: 'Assigned To',
    })
  }
  columns.push({
    accessor: 'alerts_for',
    label: 'Alert On',
  })
  columns.push({
    accessor: 'type',
    label: 'Type',
    format: (value) => value[0].toUpperCase() + value.slice(1),
  })
  columns.push({
    accessor: 'playbooks_for',
    label: 'Playbooks',
  })
  if (flags.rtcOrgLevelAlertsTableColumns2022) {
    columns.push({
      accessor: 'trigger_type',
      label: 'Trigger Type',
    })
  }

  const actions = []
  if (flags.cloudRtcOrgLevelEditPage2022) {
    actions.push({
      label: 'Edit Org Level',
      fn: (event, alert) => {
        history.push(`/realtime_coaching/alerts/${alert.id}`)
      },
      icon: <IconBriefcase />,
    })
  }
  actions.push({
    label: 'Edit',
    fn: (event, alert) => {
      setShowEditModal(true)
      setEditedAlert(alert)
    },
    icon: <IconPencil />,
  })
  if (!isReadOnly) {
    actions.push({
      label: 'Delete',
      fn: (event, alert) => {
        setShowDeleteModal(true)
        setDeletedAlert(alert)
      },
      icon: <IconTrash />,
    })
  }

  const handleBaltoManagerFiltersSubmit = ({ userId, userOrganizationId }) => {
    dispatch(
      loadAlertConfigurations({
        userId,
        userOrganizationId,
      })
    )
    dispatch(fetchAgentsByOrg(userOrganizationId))
    dispatch(fetchManagersByOrg(userOrganizationId))
    dispatch(setFilter({ agents: [] }))
    dispatch(setFilter({ createdBy: [] }))
    dispatch(setFilter({ alertStatus: [] }))
    dispatch(setFilter({ assignedTo: [] }))
    dispatch(setFilter({ tags: [] }))
    dispatch(setFilter({ nullableDateRange: { startDate: null, endDate: null } }))
    dispatch(setFilter({ nullableDateRangeIsValid: true }))
    dispatch(loadPlaybookNamesAndCids({ userOrganizationId }))
  }

  return (
    <>
      <Helmet>
        <title>
          {customBranding?.customBrandingDisplayName || 'Balto Cloud'} | Real Time Alerts
        </title>
      </Helmet>

      <AlertHeader readOnly={isReadOnly} loading={loading} />

      {isBaltoAdmin && <BaltoAdminManagerFilter onSubmit={handleBaltoManagerFiltersSubmit} />}
      <AlertFilters />

      <BasicModal
        canCloseOnDimmerClick={false}
        show={showEditModal}
        onClose={() => {
          // eslint-disable-next-line no-alert
          if (window.confirm('Are you sure you want to leave without saving?')) {
            setShowEditModal(false)
          }
        }}
        title={`Editing Alert ${editedAlert?.name.value || ''}`}
        size="small"
      >
        {editedAlert && (
          <EditAlertForm
            editedAlert={editedAlert}
            closeModal={() => setShowEditModal(false)}
            configPaths={newAlertConfiguration.configPaths}
            readOnly={isReadOnly}
          />
        )}
      </BasicModal>

      <BasicModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title="Delete Alert"
        size="tiny"
      >
        {deletedAlert && (
          <ConfirmForm
            valueToSubmit={deletedAlert}
            bodyText={`Are you sure you want to delete the “${deletedAlert.name.value}” alert? This action cannot be reversed.`}
            danger
            primaryButtonText="Delete Alert"
            handleSubmit={deleteAlert}
            modalClose={() => setShowDeleteModal(false)}
          />
        )}
      </BasicModal>

      <Segment className="not-padded">
        <AdvancedTable
          loading={loading}
          rows={alertConfigs}
          columns={columns}
          actions={actions}
          striped={false}
          pagination
          stickyHeader
          stickyAction
          hoverable={false}
          testid="rtc-alerts-table"
        />
      </Segment>
    </>
  )
}

export const Alerts = withLDConsumer()(AlertsPage)
