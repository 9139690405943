// Action Types
export const SET_LOADING = 'analytics/setLoading'
export const SET_INITIAL_LOAD_COMPLETE = 'analytics/setInitialLoadComplete'
export const SET_DATA = 'analytics/setData'
export const SET_FILTER = 'analytics/setFilter'
export const SET_DATE_RANGE_FILTER = 'analytics/setDateRangeFilter'
export const SET_CALLS_VIEW_FILTERS = 'analytics/setCallsViewFilters'
export const CLEAR_FILTERS = 'analytics/clearFilters'
export const CLEAR_FILTERS_MINUS_ORG = 'analytics/clearFiltersMinusOrg'
export const CLEAR_ORG_FILTER = 'analytics/clearOrgFilter'
export const CLEAR_REPORT_DATA = 'analytics/clearReportData'
export const CLEAR_CALLS_VIEW_FILTERS = 'analytics/clearCallsViewFilters'

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setInitialLoadComplete = () => ({ type: SET_INITIAL_LOAD_COMPLETE })
export const setData = (payload) => ({ type: SET_DATA, payload })
export const setFilter = (payload) => ({ type: SET_FILTER, payload })
export const setDateRangeFilter = (payload) => ({ type: SET_DATE_RANGE_FILTER, payload })
export const setCallsViewFilters = (payload) => ({ type: SET_CALLS_VIEW_FILTERS, payload })
export const clearFilters = (payload) => ({ type: CLEAR_FILTERS, payload })
export const clearFiltersMinusOrg = (payload) => ({ type: CLEAR_FILTERS_MINUS_ORG, payload })
export const clearOrgFilter = () => ({ type: CLEAR_ORG_FILTER })
export const clearReportData = () => ({ type: CLEAR_REPORT_DATA })
export const clearCallsViewFilters = () => ({ type: CLEAR_CALLS_VIEW_FILTERS })

const fetchedFilterDataFields = {
  agents: [],
  tags: [],
  playbooks: [],
  dispositions: [],
  deckCategories: [],
  postcallCategories: [],
}

// State
export const initialState = {
  data: {
    // Data for filters
    ...fetchedFilterDataFields,
    selectedSavedFilterId: null,

    // Overview
    callsCount: 0,
    averageHandleTime: 0,
    winCount: 0,
    winPercent: 0,
    activeUserCount: 0,
    totalUserCount: 0,

    previousCallsCount: 0,
    previousAverageHandleTime: 0,
    previousWinCount: 0,
    previousWinPercent: 0,
    previousActiveUserCount: 0,
    previousTotalUserCount: 0,
    previousChecklistUsage: [],

    topNotifications: { by_count: [], by_percent: [] },
    topDeckItems: { by_count: [], by_percent: [] },

    // Checklist
    checklistUsage: [],
    checklistUsageByItem: [],
    checklistItems: [],
    checklistItemsByUser: [],
    checklistItemsByDate: [],
    checklistItemsByCall: [],
    checklistItemsByCallCursors: { olderCursor: null, newerCursor: null },

    // Deck
    deckUsage: [],
    deckUsageByItem: [],
    deckItems: [],
    deckItemsByDate: [],
    deckItemsByUser: [],
    deckItemsByCall: [],
    deckWinRate: [],
    deckItemsByCallCursors: { olderCursor: null, newerCursor: null },
    responseCountsByDate: [],
    responseCountsByDateAndUser: [],

    // Notifications
    notificationsUsage: [],
    notificationsUsageByItem: [],
    notificationsItems: [],
    notificationsItemsByDate: [],
    notificationsItemsByUser: [],
    notificationsItemsByCall: [],
    notificationsItemsByCallCursors: { olderCursor: null, newerCursor: null },

    // Post Call
    postcallUsage: [],
    postcallUsageByItem: [],
    postcallItems: [],
    postcallItemsByDate: [],
    postcallItemsByUser: [],
    postcallItemsByCall: [],
    postcallItemsByCallCursors: { olderCursor: null, newerCursor: null },

    // Usage
    userCountsByDate: [],
    usersWithoutCalls: [],
    callCountsByDate: [],
    callCountsByUser: [],

    // CSV
    callsDownloaded: 'None in Progress',
  },
  loading: {
    overviewPage: false,
    checklistPage: false,
    checklistItemsByCall: false, // special case
    deckPage: false,
    notificationsPage: false,
    postcallPage: false,
    usagePage: false,
    exportCSVPage: false,
    deckPageWins: false, // special case
    deckItemsByCall: false,
    agents: false,
    playbooks: false,
    deckCategories: false,
    postcallCategories: false,
    tags: false,
  },
  filters: {
    ...fetchedFilterDataFields,
    organization: '',
    includeManagers: true,
    callDuration: '1',
    maxCallDuration: '',
    startDate: new Date(),
    endDate: new Date(),
    isWin: null,
  },
  callsViewFilters: {
    entries: [],
    with_events: false,
  },
  initialLoadComplete: false,
}

// Reducer
export default function analyticsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INITIAL_LOAD_COMPLETE:
      return { ...state, initialLoadComplete: true }
    case SET_DATA:
      return { ...state, data: { ...state.data, ...action.payload } }
    case SET_FILTER:
      return { ...state, filters: { ...state.filters, ...action.payload } }
    case SET_DATE_RANGE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      }
    case CLEAR_FILTERS:
      if (action.payload) {
        const clearObject = action.payload.reduce((acc, filter) => {
          acc[filter] = initialState.filters[filter]
          return acc
        }, {})
        return { ...state, filters: { ...state.filters, ...clearObject } }
      }
      return { ...state, filters: { ...initialState.filters } }
    case CLEAR_FILTERS_MINUS_ORG:
      return {
        ...state,
        filters: { ...initialState.filters, organization: state.filters.organization },
      }
    case CLEAR_ORG_FILTER:
      return {
        ...state,
        data: { ...initialState.data, selectedSavedFilterId: state.data.selectedSavedFilterId },
        filters: { ...state.filters, ...fetchedFilterDataFields },
      }
    case SET_CALLS_VIEW_FILTERS:
      return { ...state, callsViewFilters: { ...state.callsViewFilters, ...action.payload } }
    case CLEAR_CALLS_VIEW_FILTERS:
      return { ...state, callsViewFilters: { ...initialState.callsViewFilters } }
    case CLEAR_REPORT_DATA:
      return {
        ...state,
        data: {
          ...initialState.data,
          selectedSavedFilterId: state.data.selectedSavedFilterId,
          agents: state.data.agents,
          tags: state.data.tags,
          playbooks: state.data.playbooks,
          dispositions: state.data.dispositions,
          postcallCategories: state.data.postcallCategories,
          deckCategories: state.data.deckCategories,
        },
      }
    case SET_LOADING:
      return { ...state, loading: { ...state.loading, ...action.payload } }
    default:
      return state
  }
}
