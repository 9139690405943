import React, { useRef, useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, isEqual } from 'lodash'
import { Link, useLocation } from 'react-router-dom'
import { Popup, Button } from 'semantic-ui-react'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { IconDownload } from '@tabler/icons-react'
import queryString from 'query-string'

import {
  loadInitialData,
  fetchSummariesForCallIds,
  clearAllFiltersAndFetchCalls,
  fetchCalls,
  downloadCsv,
  clearSavedFilter,
  setSavedFilterById,
} from '@/reducers/callSearch/callSearch.actions'
import { CustomColumns } from '@/components/tables/CustomColumns/CustomColumns'
import { CALL_SEARCH_TABLE_NAME } from '@/reducers/customColumns/customColumns.constants'
import {
  isSummaryColumnHidden,
  getMemoizedColumns,
  getDynamicColumns,
  getCompleteColumns,
} from '@/reducers/customColumns/customColumns.helpers'
import { SavedFilters } from '@/components/savedFilters/SavedFilters'
import { CALL_EXPLORER_FILTER_TYPE } from '@/reducers/savedFilters/savedFilters.constants'
import { fetchSavedFilters } from '@/reducers/savedFilters/savedFilters.actions'

import { CallSearchFilters } from './CallSearchFilters'
import { CallSearchTable } from './components/CallSearchTable'
import { DEFAULT_CALL_COLUMNS } from './callColumns'

import './CallSearchPage.scss'

const CallSearchPage = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [rowIdsRendered, setRowIdsRendered] = useState([])
  const {
    organizationid: currentUserOrgId,
    own_organization_id: ownOrganizationId,
    hierarchy_manager: isHierarchyManager,
  } = useSelector((state) => state.currentUser)
  const childOrgSelected = isHierarchyManager && currentUserOrgId !== ownOrganizationId
  const {
    loading,
    calls,
    filters,
    data: { selectedSavedFilterId },
  } = useSelector((state) => state.callSearch)
  const { savedFilterList } = useSelector((state) => state.savedFilters)
  const { customColumns } = useSelector((state) => state.customColumns)
  const savedCustomColumns = customColumns[CALL_SEARCH_TABLE_NAME]
  const callExplorerSavedFilters = savedFilterList[CALL_EXPLORER_FILTER_TYPE] || []
  const { search } = location
  const params = queryString.parse(search)
  const localStorageCallIds = JSON.parse(localStorage.getItem('linkedCallIds'))
  const searchByCallIds = params?.call_ids || localStorageCallIds
  const isSearchByCallIds = Boolean(searchByCallIds)
  const summaryColumnHidden = isSummaryColumnHidden(savedCustomColumns)
  const subheaderRef = useRef(null)
  const dataGridAdditionalHeaderOffset = subheaderRef?.current?.clientHeight || 50

  const updateRowIdsRendered = (rowIds) => {
    if (!isEqual(rowIds, rowIdsRendered)) {
      setRowIdsRendered(rowIds)
    }
  }

  const DEFAULT_COLUMNS = [
    {
      label: 'Call ID',
      accessor: 'call_id',
      isSearchable: true,
      is_locked: true,
      sticky: true,
      format: (id) => {
        // Only append params if not call_id search
        const callExplorerLink = `/call-explorer/${id}${!isSearchByCallIds ? search : ''}`

        return (
          <Link to={callExplorerLink} className="table-link call-explorer-link" target="_blank">
            {id}
          </Link>
        )
      },
    },
    ...DEFAULT_CALL_COLUMNS,
  ]

  const columns = useMemo(
    () => getMemoizedColumns(savedCustomColumns, DEFAULT_COLUMNS),
    [savedCustomColumns, search]
  )
  const dynamicColumns = useMemo(
    () => getDynamicColumns(summaryColumnHidden, calls),
    [columns, calls, rowIdsRendered, savedCustomColumns, summaryColumnHidden]
  )
  const completeColumns = getCompleteColumns(columns, dynamicColumns)

  const handleDownloadCsv = async () => {
    await dispatch(downloadCsv(completeColumns))
  }

  const handleClearFilters = () => {
    dispatch(clearAllFiltersAndFetchCalls())
  }

  useEffect(() => {
    const getInitialSearch = async () => {
      if (localStorageCallIds) {
        await dispatch(
          loadInitialData(queryString.stringify({ ...params, call_ids: localStorageCallIds }))
        )
        localStorage.removeItem('linkedCallIds')
      } else {
        await dispatch(loadInitialData(search))
      }
    }

    dispatch(fetchSavedFilters(CALL_EXPLORER_FILTER_TYPE))

    if (!isEmpty(search)) {
      getInitialSearch()
    } else {
      // Use the default
      dispatch(fetchCalls({ filters: { ...filters, organizationId: currentUserOrgId } }))
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(rowIdsRendered) && !summaryColumnHidden) {
      const callsRenderedWithoutSummariesLoaded = calls
        .filter((call) => rowIdsRendered.includes(call.call_id)) // Only fetches summaries for calls that have been rendered
        .filter(({ call_summary }) => call_summary === undefined)
        .map(({ call_id }) => call_id)
      if (!isEmpty(callsRenderedWithoutSummariesLoaded)) {
        dispatch(fetchSummariesForCallIds(callsRenderedWithoutSummariesLoaded))
      }
    }
  }, [rowIdsRendered, savedCustomColumns, calls, summaryColumnHidden])

  const handleSelectSavedFilter = (option) => {
    if (!option) {
      dispatch(clearSavedFilter())
    } else {
      const savedFilterId = option.value
      dispatch(setSavedFilterById(savedFilterId))
    }
  }

  return (
    <>
      <header className="data-grid-header page-header">
        <h1 className="breadcrumb-header" data-testid="calls-header">
          Call Explorer
        </h1>
        <div className="flex-align-center medium-gap">
          <CustomColumns tableName={CALL_SEARCH_TABLE_NAME} defaultColumns={DEFAULT_COLUMNS} />
          <Popup
            inverted
            content={
              loading.csvDownload
                ? 'Downloading...'
                : isEmpty(calls)
                  ? 'No calls available for download'
                  : 'Download calls'
            }
            position="bottom right"
            trigger={
              <div>
                <Button
                  disabled={loading.csvDownload || isEmpty(calls)}
                  icon
                  data-testid="csv-download-button"
                  secondary
                  onClick={handleDownloadCsv}
                  className="svg-button"
                  loading={loading.csvDownload}
                >
                  <IconDownload />
                </Button>
              </div>
            }
          />
          {!childOrgSelected && (
            <SavedFilters
              hideEditCreate
              hideButton={isEmpty(callExplorerSavedFilters)}
              filterType={CALL_EXPLORER_FILTER_TYPE}
              selectedSavedFilterId={selectedSavedFilterId}
              handleSelectSavedFilter={handleSelectSavedFilter}
            />
          )}
        </div>
      </header>
      <header className="data-grid-subheader" ref={subheaderRef}>
        {!isSearchByCallIds && (
          <CallSearchFilters params={params} childOrgSelected={childOrgSelected} />
        )}
      </header>

      <CallSearchTable
        columns={completeColumns}
        loading={loading}
        filters={filters}
        calls={calls}
        handleClearFilters={handleClearFilters}
        updateRowIdsRendered={updateRowIdsRendered}
        organizationId={
          isSearchByCallIds ? params.organizationId : filters.organizationId || currentUserOrgId
        }
        dataGridAdditionalHeaderOffset={dataGridAdditionalHeaderOffset}
      />
    </>
  )
}

export default withLDConsumer()(CallSearchPage)
