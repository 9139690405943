import { saveAs } from 'file-saver'
import { toast } from 'react-toastify'

import { first } from 'lodash'
import queryString from 'query-string'
import pluralize from 'pluralize'
import React from 'react'
import { formatScorecardFilters, parseDashboardToCsv } from './helpers'
import { setLoading, setData } from './scorecards.redux'
import { fetchingAPI, apiService } from '../../api'

export const fetchCriteriaScoresByMeasureId = (scorecardId, measureId) => async (dispatch) => {
  try {
    dispatch(setLoading('criteriaScoresByMeasure', true))

    const criteriaScoresObject = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/${scorecardId}/criteria_by_measure/${measureId}`,
      'GET',
      dispatch
    )

    if (criteriaScoresObject) {
      dispatch(setData('criteriaScoresByMeasure', criteriaScoresObject))
    }
  } catch (err) {
    toast.error('Could not fetch criteria scores')
  } finally {
    dispatch(setLoading('criteriaScoresByMeasure', false))
  }
}

export const fetchCriteriaUpdateAuditEventsForOrg =
  (isCopilot = false) =>
  async (dispatch, getState) => {
    try {
      const { scorecards } = getState()
      dispatch(setData('criteriaAuditEvents', []))
      dispatch(setLoading('criteriaAuditEvents', true))
      const filterString = formatScorecardFilters(scorecards.filters, null, isCopilot)
      const criteriaAuditEvents = await fetchingAPI(
        `${apiService.scorecard}/history/criteria_events?${filterString}`,
        'GET',
        dispatch
      )

      if (criteriaAuditEvents) {
        dispatch(setData('criteriaAuditEvents', criteriaAuditEvents))
      }
    } catch (err) {
      toast.error('Could not fetch criteria audit events')
      console.error(err)
    } finally {
      dispatch(setLoading('criteriaAuditEvents', false))
    }
  }
export const scoreCopilotScorecard =
  (organizationId, callIds = [], scorecardConfigId) =>
  async (dispatch) => {
    const params = queryString.stringify({ requested_organization_id: organizationId })

    try {
      const body = JSON.stringify({
        call_ids: callIds,
        scorecard_config_id: scorecardConfigId,
      })

      await fetchingAPI(
        `${apiService.scorecard}/scoring/scores/copilot/score?${params}`,
        'POST',
        dispatch,
        body
      )
      toast.success(
        <div>
          <p>{`${pluralize('Call', callIds.length)} submitted for scoring. Results will be ready shortly — refresh the page in a few minutes to view them.`}</p>
        </div>
      )
    } catch (err) {
      toast.error(`Failed to Score: ${err}`)
    }
  }

export const fetchQaAuditEventsByCallId = (callId, organizationId) => async (dispatch) => {
  try {
    dispatch(setLoading('qaAuditEvents', true))

    const eventsResponse = await fetchingAPI(
      `${apiService.scorecard}/history/calls/${callId}?requested_organization_id=${organizationId}`,
      'GET',
      dispatch
    )

    dispatch(setData('qaAuditEvents', eventsResponse))
  } catch (err) {
    console.info(`No QA audit events found for call: ${callId}`)
  } finally {
    dispatch(setLoading('qaAuditEvents', false))
  }
}

// CSV

// TODO: double check if this needs to be called instead of the default widget csv functionality?
export const exportScoreDashboardToCsv = (rows, headers) => async (dispatch, getState) => {
  dispatch(setLoading('csv', true))
  const { scorecards } = getState()
  const { data, filters } = scorecards
  const { aggregateFilters, targetScorecardConfig } = data
  const scorecardNameString = first(filters.scorecards).label.replace(/\s/g, '_')
  const dateRangeString = ''.concat(aggregateFilters.start_date, '-', aggregateFilters.end_date)
  const fileName = ''.concat(
    'balto-qa-dashboard-',
    scorecardNameString,
    '-',
    dateRangeString,
    '.csv'
  )

  const userScoreKeys = ['user_full_name', 'overall_score']
  targetScorecardConfig.sections.forEach((section) => {
    userScoreKeys.push(section.name)
  })

  try {
    const response = parseDashboardToCsv(rows, headers)
    saveAs(response, fileName)
  } catch (err) {
    toast.error('Failed to fetch CSV file')
  } finally {
    dispatch(setLoading('csv', false))
  }
}
