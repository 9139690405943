import React, { useContext, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'

import { Matrix } from '@/components/charts/Matrix'
import WidgetContext from '@/components/widget/widget.context'
import WidgetContainer from '@/components/widget/WidgetContainer'
import { WidgetPagination } from '@/components/widget/WidgetPagination'

import { paginateItemsByCall } from '@/reducers/analytics/analytics.actions'
import { formatItemsByCall, groupCallDetails } from './widgetHelpers'

export const ItemsByCallWidget = ({ section }) => {
  const dispatch = useDispatch()
  const { data, loading, filters } = useSelector((state) => state.analytics)
  const { itemDetail, handleChangeItemDetail, handleResetWidgetFilters } = useContext(WidgetContext)
  const { olderCursor, newerCursor } = data[`${section}ItemsByCallCursors`]
  const items = data[`${section}Items`]
  const itemsByCall = data[`${section}ItemsByCall`]

  const formattedData = useMemo(() => {
    return formatItemsByCall(items, itemsByCall, section)
  }, [data])

  const callDetails = useMemo(() => {
    return groupCallDetails(itemsByCall, section)
  }, [data])

  const handlePagination = (cursor, oldest) => {
    dispatch(paginateItemsByCall(cursor, oldest, section))
  }

  useEffect(() => {
    handleChangeItemDetail({})
  }, [filters])

  useEffect(() => {
    handleResetWidgetFilters()
  }, [data])

  return (
    <WidgetContainer
      medium
      matrix
      withPagination
      loading={loading[`${section}Page`] || loading[`${section}ItemsByCall`]}
      noData={isEmpty(formattedData)}
      withDetail={!isEmpty(itemDetail)}
      dataForDetail={itemDetail}
      table={<table />}
      chart={
        <>
          <Matrix
            data={formattedData}
            tooltip={(dataSet) => {
              return (
                <div>
                  <h3>{dataSet.tooltipName}</h3>
                  <div>{dataSet.tooltipDate}</div>
                  <p>{dataSet.tooltipDuration}</p>

                  <div>{dataSet.tooltipItem}</div>
                </div>
              )
            }}
            header={(label) => {
              return (
                <div>
                  <strong>{label.name}</strong>
                  <div>{label.duration}</div>
                  <div>{label.date}</div>
                  {label.voip_call_id && <div>{label.voip_call_id}</div>}
                </div>
              )
            }}
            onClick={(callId, item) => {
              handleChangeItemDetail(callDetails[`${callId}-${item}`])
            }}
          />

          <WidgetPagination
            olderCursor={olderCursor}
            newerCursor={newerCursor}
            queryDataByCursor={handlePagination}
          />
        </>
      }
    />
  )
}
