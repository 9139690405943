import Papa from 'papaparse'

import { toast } from 'react-toastify'
import queryString from 'query-string'
import { first, isEmpty } from 'lodash'

import { getTagOptions, findPreviousDateRangeFromCurrentFilters } from '@/utils/helpers'
import {
  formatScorecardFilters,
  formatManualScorecardTableData,
  formatManualScorecard,
  formatDisputes,
} from './helpers'
import { fetchingAPI, apiService } from '../../api'
import { setLoading, setData, setFilter, setTableHeaders, setTableRows } from './scorecards.redux'

export const fetchAgentsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading('agents', true))

  try {
    const { users } = await fetchingAPI(
      `${apiService.web}/api/organizations/${organizationId}/users`,
      'GET',
      dispatch
    )
    const agentOptions = users
      .map((agent) => ({
        value: agent.id,
        label: `${agent.first_name} ${agent.last_name}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    dispatch(setData('agents', agentOptions))
    dispatch(setData('currentOrganizationId', organizationId))
  } catch (err) {
    toast.error('Failed to fetch agents')
  } finally {
    dispatch(setLoading('agents', false))
  }
}

export const fetchTagsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading({ tags: true }))

  try {
    const [tags, tagCategories] = await Promise.all([
      fetchingAPI(`${apiService.web}/api/organizations/${organizationId}/tags`, 'GET', dispatch),
      fetchingAPI(
        `${apiService.web}/api/organizations/${organizationId}/tags/categories`,
        'GET',
        dispatch
      ),
    ])

    const tagOptionsByCategory = []

    // Created grouped tag categories
    tagCategories.forEach((cat) => {
      tagOptionsByCategory.push({
        label: cat.name,
        options: getTagOptions(tags.filter((tag) => tag.tag_category_id === cat.id)),
      })
    })

    // Add uncategorized tags
    tagOptionsByCategory.push({
      label: 'Uncategorized',
      options: getTagOptions(tags.filter((tag) => !tag.tag_category_id)),
    })

    dispatch(setData('tags', tagOptionsByCategory))
  } catch (err) {
    toast.error('Failed to fetch tags')
  } finally {
    dispatch(setLoading({ tags: false }))
  }
}

export const fetchPlaybooksByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading('playbooks', true))

  try {
    const configProperties = queryString.stringify({ requested_properties: 'id,name,cid' })
    const playbooks = await fetchingAPI(
      `${apiService.web}/api/${organizationId}/configs?${configProperties}&active_only=True`,
      'GET',
      dispatch
    )
    const playbookOptions = playbooks
      .map((playbook) => ({
        value: playbook.cid,
        id: playbook.id,
        label: playbook.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    dispatch(setData('playbooks', playbookOptions))
  } catch (err) {
    toast.error('Failed to fetch playbooks')
  } finally {
    dispatch(setLoading('playbooks', false))
  }
}

export const fetchPlaybookById = (playbookId) => async (dispatch) => {
  dispatch(setLoading('targetPlaybook', true))

  try {
    const playbook = await fetchingAPI(
      `${apiService.web}/api/configs/${playbookId}`,
      'GET',
      dispatch
    )

    dispatch(setData('targetPlaybook', playbook.config))
  } catch (err) {
    toast.error('Failed to fetch target playbook')
  } finally {
    dispatch(setLoading('targetPlaybook', false))
  }
}

export const fetchScorecardConfigById = (scorecardConfigId) => async (dispatch) => {
  dispatch(setLoading('targetScorecardConfig', true))

  try {
    const scorecardConfig = await fetchingAPI(
      `${apiService.scorecard}/scoring/scorecards/scorecard/${scorecardConfigId}`,
      'GET',
      dispatch
    )
    dispatch(setData('targetScorecardConfig', scorecardConfig))
  } catch (err) {
    toast.error('Failed to fetch target scorecard config')
  } finally {
    dispatch(setLoading('targetScorecardConfig', false))
  }
}

export const fetchAggregateScoresByConfigId =
  (scorecardId, returnFormat = null) =>
  async (dispatch, getState) => {
    dispatch(setLoading('scores', true))
    dispatch(setLoading('aggregateScores', true))
    dispatch(setLoading('aggregateFilters', true))
    const { scorecards } = getState()
    const filterString = formatScorecardFilters(scorecards.filters)

    try {
      let url = `${apiService.scorecard}/scoring/scores/byConfigId/${scorecardId}/aggregate?${filterString}`
      if (returnFormat) url = url.concat(`&return_format=${returnFormat}`)

      const scoreData = await fetchingAPI(url, 'GET', dispatch)
      dispatch(setData('allScores', scoreData.scorecard_ids || []))
      if (returnFormat === 'table') {
        dispatch(setTableHeaders('advancedExports', scoreData.headers || []))
        dispatch(setTableRows('advancedExports', scoreData.rows || []))
      } else {
        dispatch(setData('aggregateScores', scoreData.aggregate_scores || {}))
      }
      dispatch(setData('aggregateFilters', scoreData.filters || {}))
      dispatch(fetchScorecardConfigById(scorecardId))
    } catch (err) {
      toast.error('Failed to fetch scores')
    } finally {
      dispatch(setLoading('scores', false))
      dispatch(setLoading('aggregateScores', false))
      dispatch(setLoading('aggregateFilters', false))
    }
  }

export const fetchStreamingScores = () => (dispatch, getState) => {
  const { scorecards } = getState()
  const filterString = formatScorecardFilters(scorecards.filters)
  const url = `${apiService.scorecard}/scoring/scores/scorecards/streaming/calls?${filterString}`

  dispatch(setLoading('advancedExportsStreamedCsv', true))

  fetchingAPI(url, 'POST', dispatch)
    .then((csvStream) => {
      // get stream reader from fetch response body stream
      const responseReader = csvStream.getReader()
      let streamProgress = ''
      let firstRow = ''

      // this function reads stream, then if done == true we download and clean up.
      const readStream = () =>
        responseReader
          .read()
          .then(({ value, done }) => {
            if (done) {
              const parsedCsvData = Papa.parse(streamProgress, {
                header: true,
                skipEmptyLines: true,
                delimiter: ',',
              })
              dispatch(setLoading('advancedExportsStreamedCsv', false))
              if (parsedCsvData.data.length > 0) {
                firstRow = Object.keys(parsedCsvData.data[0]).map((header) => ({
                  accessor: header.trim(),
                  label: header.trim(),
                }))
                dispatch(setTableHeaders('advancedExports', firstRow || []))
                dispatch(setTableRows('advancedExports', parsedCsvData.data || []))
              }
              return null
            }
            const decodedCsvData = new TextDecoder('utf-8').decode(value)
            streamProgress += decodedCsvData
            return readStream()
          })
          .catch((e) => {
            toast.error('Failed to fetch scores')
            console.error(e)
            dispatch(setLoading('advancedExportsStreamedCsv', false))
          })
      readStream()
    })
    .catch((e) => {
      toast.error('Failed to fetch scores')
      console.error(e)
      dispatch(setLoading('advancedExportsStreamedCsv', false))
    })
}

export const fetchScorecardByScorecardConfigs =
  (isPreview = false) =>
  async (dispatch, getState) => {
    dispatch(setLoading('scores', true))
    dispatch(setLoading('aggregateScores', true))
    dispatch(setLoading('aggregateFilters', true))
    const { scorecards } = getState()
    const filterString = formatScorecardFilters(scorecards.filters, null, false, isPreview)

    try {
      const url = `${apiService.scorecard}/scoring/scores/scorecards/calls?${filterString}`
      const scoreData = await fetchingAPI(url, 'GET', dispatch)
      dispatch(setTableHeaders('advancedExports', scoreData.headers || []))
      dispatch(setTableRows('advancedExports', scoreData.rows || []))
      dispatch(setData('aggregateFilters', scoreData.filters || {}))
    } catch (err) {
      console.error(err)
      toast.error('Failed to fetch scores')
    } finally {
      dispatch(setLoading('scores', false))
      dispatch(setLoading('aggregateScores', false))
      dispatch(setLoading('aggregateFilters', false))
    }
  }

export const fetchAggregateScoresByMultipleScorecards =
  (returnFormat = null) =>
  async (dispatch, getState) => {
    dispatch(setLoading('scores', true))
    dispatch(setLoading('aggregateScores', true))
    dispatch(setLoading('aggregateFilters', true))
    const { scorecards } = getState()
    const filterString = formatScorecardFilters(scorecards.filters)

    try {
      let url = `${apiService.scorecard}/scoring/scores/aggregate/multiple?${filterString}`
      if (returnFormat) url = url.concat(`&return_format=${returnFormat}`)

      const scoreData = await fetchingAPI(url, 'GET', dispatch)
      dispatch(setData('allScores', scoreData.scorecard_ids || []))
      if (returnFormat === 'table') {
        dispatch(setTableHeaders('advancedExports', scoreData.headers || []))
        dispatch(setTableRows('advancedExports', scoreData.rows || []))
      } else {
        dispatch(setData('aggregateScores', scoreData.aggregate_scores || {}))
      }
      dispatch(setData('aggregateFilters', scoreData.filters || {}))
    } catch (err) {
      console.error(err)
      toast.error('Failed to fetch scores')
    } finally {
      dispatch(setLoading('scores', false))
      dispatch(setLoading('aggregateScores', false))
      dispatch(setLoading('aggregateFilters', false))
    }
  }

export const fetchAggregateScoresByUserByConfigId = (scorecardId) => async (dispatch, getState) => {
  dispatch(setLoading('aggregateScoresByUser', true))
  dispatch(setLoading('aggregateFilters', true))
  const { scorecards } = getState()
  const filterString = formatScorecardFilters(scorecards.filters)

  try {
    const scoreData = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/byConfigId/${scorecardId}/aggregate?group_by=agents&return_format=table&metrics=sections&${filterString}`,
      'GET',
      dispatch
    )
    dispatch(setTableRows('scorecardDashboard', scoreData.rows || []))
    dispatch(setTableHeaders('scorecardDashboard', scoreData.headers || []))
    dispatch(setData('callsCount', scoreData.call_count || ''))
    dispatch(setData('aggregateFilters', scoreData.filters || {}))
    dispatch(fetchScorecardConfigById(scorecardId))
  } catch (err) {
    toast.error('Failed to fetch scores')
  } finally {
    dispatch(setLoading('aggregateScoresByUser', false))
    dispatch(setLoading('aggregateFilters', false))
  }
}

export const fetchAggregateScores = (returnFormat) => async (dispatch, getState) => {
  dispatch(setLoading('scores', true))
  dispatch(setLoading('aggregateScores', true))
  dispatch(setLoading('aggregateFilters', true))
  const { scorecards } = getState()
  const filterString = formatScorecardFilters(scorecards.filters)
  try {
    if (!isEmpty(scorecards.filters.scorecards)) {
      const firstScorecard = first(scorecards.filters.scorecards)
      let url = `${apiService.scorecard}/scoring/scores/byConfigId/${firstScorecard.id}/aggregate?${filterString}`
      if (returnFormat) url = url.concat(`&return_format=${returnFormat}`)

      const scoreData = await fetchingAPI(url, 'GET', dispatch)
      dispatch(setData('allScores', scoreData.scorecard_ids || []))
      dispatch(setData('scores', scoreData.scorecard_ids || []))
      if (returnFormat === 'table') {
        dispatch(setTableHeaders('advancedExports', scoreData.headers || []))
        dispatch(setTableRows('advancedExports', scoreData.rows || []))
      } else {
        dispatch(setData('aggregateScores', scoreData.aggregate_scores || {}))
      }
      dispatch(setData('aggregateFilters', scoreData.filters || {}))
      if (firstScorecard && firstScorecard.id && firstScorecard.name) {
        dispatch(fetchScorecardConfigById(firstScorecard.id))
        dispatch(
          setFilter('scorecards', [
            {
              value: firstScorecard.id,
              label: firstScorecard.name,
              sid: firstScorecard.sid,
            },
          ])
        )
      }
    } else {
      dispatch(setData('allScores', []))
      dispatch(setData('scores', []))
      dispatch(setData('aggregateScores', {}))
      dispatch(setData('aggregateFilters', {}))
      dispatch(setFilter('scorecards', []))
    }
  } catch (err) {
    toast.error('Failed to fetch scores')
  } finally {
    dispatch(setLoading('scores', false))
    dispatch(setLoading('aggregateScores', false))
    dispatch(setLoading('aggregateFilters', false))
  }
}

export const fetchAggregateScoresByOrgIdMultipleScorecards =
  (organizationId) => async (dispatch, getState) => {
    dispatch(setLoading('reporting', true))
    dispatch(setLoading('reportingComparison', true))
    const { scorecards } = getState()
    const { startDate, endDate } = scorecards.filters
    const [newStartDate, newEndDate] = findPreviousDateRangeFromCurrentFilters(startDate, endDate)
    const filterString = formatScorecardFilters(scorecards.filters)
    const previousFilterString = formatScorecardFilters({
      ...scorecards.filters,
      startDate: newStartDate,
      endDate: newEndDate,
    })

    try {
      const scoreData = await fetchingAPI(
        `${apiService.scorecard}/scoring/scores/aggregate/multiple?requested_organization_id=${organizationId}&${filterString}`,
        'GET',
        dispatch
      )
      dispatch(setData('reporting', scoreData))
      dispatch(setData('aggregateFilters', scoreData.filters || {}))

      const previousRangeScoreData = await fetchingAPI(
        `${apiService.scorecard}/scoring/scores/aggregate/multiple?requested_organization_id=${organizationId}&${previousFilterString}`,
        'GET',
        dispatch
      )
      dispatch(setData('reportingComparison', previousRangeScoreData))
    } catch (err) {
      dispatch(setFilter('reporting', {}))
      toast.error('Failed to fetch scores')
    } finally {
      dispatch(setLoading('reporting', false))
      dispatch(setLoading('reportingComparison', false))
    }
  }

export const fetchAggregateScoresByOrgIdMultipleScorecardsByDay =
  (organizationId) => async (dispatch, getState) => {
    dispatch(setLoading('reportingByDay', true))
    const { scorecards } = getState()
    const filterString = formatScorecardFilters(scorecards.filters)

    try {
      const scoreData = await fetchingAPI(
        `${apiService.scorecard}/scoring/scores/aggregate/multiple/by_date?requested_organization_id=${organizationId}&${filterString}`,
        'GET',
        dispatch
      )
      dispatch(setData('reportingByDay', scoreData))
    } catch (err) {
      dispatch(setFilter('reportingByDay', {}))
      toast.error('Failed to fetch scores')
    } finally {
      dispatch(setLoading('reportingByDay', false))
    }
  }

export const fetchAggregateScoresByOrgIdMultipleScorecardsByUser =
  (organizationId) => async (dispatch, getState) => {
    dispatch(setLoading('reportingByUser', true))
    const { scorecards } = getState()

    const filterString = formatScorecardFilters(scorecards.filters)

    try {
      const scoreData = await fetchingAPI(
        `${apiService.scorecard}/scoring/scores/aggregate/multiple/users?requested_organization_id=${organizationId}&${filterString}`,
        'GET',
        dispatch
      )
      dispatch(setData('reportingByUser', scoreData))
    } catch (err) {
      dispatch(setFilter('reportingByUser', {}))
      toast.error('Failed to fetch scores')
    } finally {
      dispatch(setLoading('reportingByUser', false))
    }
  }

export const fetchAggregateScoresByUser = () => async (dispatch, getState) => {
  dispatch(setLoading('aggregateScoresByUser', true))
  dispatch(setLoading('aggregateFilters', true))
  const { scorecards } = getState()
  const filterString = formatScorecardFilters(scorecards.filters)
  try {
    if (!isEmpty(scorecards.filters.scorecards)) {
      const firstScorecard = first(scorecards.filters.scorecards)
      const scoreData = await fetchingAPI(
        `${apiService.scorecard}/scoring/scores/byConfigId/${firstScorecard.id}/aggregate?group_by=agents&${filterString}`,
        'GET',
        dispatch
      )
      dispatch(setData('aggregateScoresByUser', scoreData.aggregate_scores || []))
      dispatch(setData('aggregateFilters', scoreData.filters || {}))
      if (firstScorecard.id && firstScorecard.name) {
        dispatch(fetchScorecardConfigById(firstScorecard.id))
        dispatch(
          setFilter('scorecards', [
            {
              value: firstScorecard.id,
              label: firstScorecard.name,
              sid: firstScorecard.sid,
            },
          ])
        )
      }
    }
  } catch (err) {
    toast.error('Failed to fetch scores')
  } finally {
    dispatch(setLoading('aggregateScoresByUser', false))
    dispatch(setLoading('aggregateFilters', false))
  }
}

export const fetchScoresByOrg = (scorecardId) => async (dispatch, getState) => {
  dispatch(setLoading('scores', true))
  dispatch(setLoading('aggregateScores', true))
  const { scorecards } = getState()
  const filterString = formatScorecardFilters(scorecards.filters)
  try {
    const scorecardResponse = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/byConfigId/${scorecardId}/aggregate?${filterString}`,
      'GET',
      dispatch
    )
    dispatch(setData('scores', scorecardResponse))
    dispatch(setData('aggregateScores', { total: 0 }))
  } catch {
    toast.error('Failed to fetch scores')
  } finally {
    dispatch(setLoading('scores', false))
    dispatch(setLoading('aggregateScores', false))
  }
}

export const fetchScoresByScorecardId = () => async (dispatch, getState) => {
  dispatch(setLoading('scoresTable', true))
  const { scorecards } = getState()
  const filterString = formatScorecardFilters(scorecards.filters)
  try {
    const scorecardId = first(scorecards.filters.scorecards).value
    const scorecardConfigs = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/byConfigId/${scorecardId}?${filterString}`,
      'GET',
      dispatch
    )
    dispatch(setData('scorecardConfigs', scorecardConfigs))
  } catch {
    toast.error('Failed to fetch all scorecards')
  } finally {
    dispatch(setLoading('scoresTable', false))
  }
}

export const fetchScoreById = (scorecardId) => async (dispatch) => {
  dispatch(setLoading('currentScore', true))

  try {
    const scorecard = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/${scorecardId}`,
      'GET',
      dispatch
    )
    dispatch(setData('currentScore', scorecard))
  } catch (err) {
    toast.error('Failed to fetch scores')
  } finally {
    dispatch(setLoading('currentScore', false))
  }
}

export const fetchScorecardsByCallId = (callId, organizationId) => async (dispatch) => {
  dispatch(setLoading('scorecards', true))

  try {
    const scorecards = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/by_call_id/${callId}?requested_organization_id=${organizationId}`,
      'GET',
      dispatch
    )
    dispatch(setData('scorecards', scorecards))
  } catch (err) {
    toast.error('Failed to fetch scorecards')
  } finally {
    dispatch(setLoading('scorecards', false))
  }
}

export const fetchScorecardsByOrg =
  (organizationId, scorecardType = null) =>
  async (dispatch) => {
    dispatch(setLoading('scorecards', true))
    let url = `${apiService.scorecard}/scoring/scorecards/current?requested_organization_id=${organizationId}`
    if (scorecardType) {
      url += `&scorecard_type=${scorecardType}`
    }
    try {
      const scorecardConfigs = await fetchingAPI(url, 'GET', dispatch)
      const sortedScorecardConfigs = scorecardConfigs.sort((a, b) => a.name?.localeCompare(b.name))

      dispatch(setData('scorecardConfigs', sortedScorecardConfigs))
      dispatch(setData('currentOrganizationId', organizationId))
    } catch (err) {
      toast.error(`Failed to fetch scorecards for organization: ${organizationId}`)
    } finally {
      dispatch(setLoading('scorecards', false))
    }
  }

export const fetchScorecardOptionsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading('scorecardOptions', true))

  try {
    const scorecardConfigs = await fetchingAPI(
      `${apiService.scorecard}/scoring/scorecards/current?requested_organization_id=${organizationId}`,
      'GET',
      dispatch
    )

    const scorecardOptions = scorecardConfigs
      .map((scorecard) => ({
        value: scorecard.id,
        label: scorecard.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    dispatch(setData('scorecardOptions', scorecardOptions))
    dispatch(setData('currentOrganizationId', organizationId))
  } catch (err) {
    toast.error(`Failed to fetch scorecards for organization: ${organizationId}`)
  } finally {
    dispatch(setLoading('scorecardOptions', false))
  }
}

export const fetchNotableCalls = (section) => async (dispatch, getState) => {
  const scoreData = {}
  scoreData.percentage_score = section.percentage_score
  dispatch(setLoading('notableCalls', true))
  const { scorecards } = getState()
  const filterString = formatScorecardFilters(scorecards.filters, scoreData)
  try {
    const scorecardId = first(scorecards.filters.scorecards).value
    const notableCallsFromApi = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/byConfigId/${scorecardId}/notableCalls/${section.section_id}?${filterString}`,
      'GET',
      dispatch
    )
    dispatch(
      setData(`aboveThreshold`, {
        ...scorecards.data?.aboveThreshold,
        [section.section_id]: notableCallsFromApi.above_threshold,
      })
    )
    dispatch(
      setData(`belowThreshold`, {
        ...scorecards.data?.belowThreshold,
        [section.section_id]: notableCallsFromApi.below_threshold,
      })
    )
  } catch (err) {
    toast.error('Failed to fetch notable calls for organization')
  } finally {
    dispatch(setLoading('notableCalls', false))
  }
}

export const fetchTargetScorecardData = (scorecardConfigId) => async (dispatch, getState) => {
  dispatch(setLoading('targetScorecardConfig', true))
  dispatch(setLoading('targetPlaybook', true))

  try {
    await dispatch(fetchScorecardConfigById(scorecardConfigId))
    const { scorecards } = getState()
    const targetPlaybookId = scorecards.data.targetScorecardConfig.config_id
    await dispatch(fetchPlaybookById(targetPlaybookId))
  } catch (err) {
    toast.error('Failed to fetch target scorecard data')
  } finally {
    dispatch(setLoading('targetScorecardConfig', false))
    dispatch(setLoading('targetPlaybook', false))
  }
}

export const addScorecard = (scorecard) => async (dispatch, getState) => {
  dispatch(setLoading('scorecards', true))

  try {
    const { scorecards } = getState()
    const { currentOrganizationId } = scorecards.data

    const body = JSON.stringify({
      ...scorecard,
      min_duration_in_seconds: scorecard.min_duration_in_minutes * 60 || 60,
    })

    const newScorecard = await fetchingAPI(
      `${apiService.scorecard}/scoring/scorecards/scorecard`,
      'POST',
      dispatch,
      body
    )

    await dispatch(fetchScorecardsByOrg(currentOrganizationId))
    await dispatch(setData('targetScorecardConfig', newScorecard))
    return newScorecard
  } catch (err) {
    toast.error('Failed to add scorecard')
    return null
  } finally {
    dispatch(setLoading('scorecards', false))
  }
}

export const newCustomSection = (configDraft) => async (dispatch) => {
  dispatch(setLoading('scorecards', true))
  try {
    const body = JSON.stringify({
      ...configDraft,
    })

    const newScorecard = await fetchingAPI(
      `${apiService.scorecard}/scoring/scorecards/scorecard/${body.id}`,
      'PUT',
      dispatch,
      body
    )
    dispatch(setData('targetScorecardConfig', newScorecard))
    return {
      newScorecard,
    }
  } catch (err) {
    toast.error('Failed to create measure')
    return null
  } finally {
    dispatch(setLoading('scorecards', false))
  }
}

export const newCustomMeasure = (configDraft) => async (dispatch, getState) => {
  dispatch(setLoading('scorecards', true))
  const {
    scorecards: { data },
  } = getState()
  const { ssid } = data?.targetScorecardSection
  try {
    const body = JSON.stringify({
      ...configDraft,
    })
    const newScorecard = await fetchingAPI(
      `${apiService.scorecard}/scoring/scorecards/scorecard/${configDraft.id}`,
      'PUT',
      dispatch,
      body
    )
    const newSection = newScorecard.sections.find(
      (scorecardSection) => scorecardSection.ssid === ssid
    )
    dispatch(setData('targetScorecardConfig', newScorecard))
    dispatch(setData('targetScorecardSection', newSection))
    return {
      newScorecard,
      newSection,
    }
  } catch (err) {
    toast.error('Failed to create measure')
    return null
  } finally {
    dispatch(setLoading('scorecards', false))
  }
}

export const copyScorecard =
  (scorecardConfig, existingCopies = 0, scorecardType = 'automated', newOrganizationId) =>
  async (dispatch, getState) => {
    dispatch(setLoading('scorecards', true))
    const copyPrompt = async (promptId) => {
      const response = await fetchingAPI(
        `${apiService.summary}/prompts/${promptId}/copy`,
        'POST',
        dispatch
      )
      return {
        oldId: promptId,
        newId: response?.id,
      }
    }

    try {
      const { scorecards } = getState()
      const { currentOrganizationId } = scorecards.data
      const newName =
        existingCopies > 0
          ? `COPY OF ${scorecardConfig.name} (${existingCopies + 1})`
          : `COPY OF ${scorecardConfig.name}`

      let copiedScorecardConfig = {
        ...scorecardConfig,
        name: newName,
        active: false,
        organization_id: newOrganizationId,
        users: [],
        users_inclusive: true,
        tags: [],
        tags_inclusive: true,
        config_cids: [],
      }

      delete copiedScorecardConfig.sid
      delete copiedScorecardConfig.uuid
      delete copiedScorecardConfig.id
      copiedScorecardConfig.sections.forEach((section) => {
        delete section.uuid
        delete section.ssid
        delete section.id
        section.measures.forEach((measure) => {
          delete measure.smid
          delete measure.uuid
          delete measure.id
          measure.criteria.forEach((criteria) => {
            delete criteria.scid
            delete criteria.uuid
            delete criteria.id
          })
        })
      })

      // find all prompts and then create copies of prompts
      const promptsToCopy = [
        copiedScorecardConfig.eligibility_prompt_id,
        ...copiedScorecardConfig.sections.flatMap((section) =>
          section.measures.flatMap((measure) =>
            measure.criteria.flatMap((criteria) => criteria.prompt_id)
          )
        ),
      ].filter((promptId) => promptId !== null)

      const scorecardConfigWithPrompts = await Promise.all(
        promptsToCopy.map((promptId) => copyPrompt(promptId))
      ).then((copiedIds) => {
        return {
          ...copiedScorecardConfig,
          eligibility_prompt_id:
            copiedIds.find(
              (promptId) => promptId.oldId === copiedScorecardConfig.eligibility_prompt_id
            )?.newId || null,
          sections: copiedScorecardConfig.sections.map((section) => ({
            ...section,
            measures: section.measures.map((measure) => ({
              ...measure,
              criteria: measure.criteria.map((criteria) => ({
                ...criteria,
                prompt_id:
                  copiedIds.find((promptId) => promptId.oldId === criteria.prompt_id)?.newId ||
                  null,
              })),
            })),
          })),
        }
      })

      copiedScorecardConfig = JSON.stringify(scorecardConfigWithPrompts)

      const newScorecard = await fetchingAPI(
        `${apiService.scorecard}/scoring/scorecards/scorecard`,
        'POST',
        dispatch,
        copiedScorecardConfig
      )

      await dispatch(fetchScorecardsByOrg(currentOrganizationId, scorecardType))
      await dispatch(setData('targetScorecardConfig', newScorecard))
    } catch (err) {
      toast.error('Failed to copy scorecard')
    } finally {
      dispatch(setLoading('scorecards', false))
    }
  }

export const editScorecard = (scorecard, section, organizationId) => async (dispatch) => {
  dispatch(setLoading('scorecards', true))

  try {
    const body = JSON.stringify({
      ...scorecard,
    })
    const newScorecard = await fetchingAPI(
      `${apiService.scorecard}/scoring/scorecards/scorecard/${scorecard.id}`,
      'PUT',
      dispatch,
      body
    )
    const newSection = newScorecard.sections.find(
      (scorecardSection) => scorecardSection.name === section.name
    )

    await dispatch(fetchScorecardsByOrg(organizationId))
    await dispatch(setData('targetScorecardConfig', newScorecard))
    await dispatch(setData('targetScorecardSection', newSection))
    return {
      newScorecard,
      newSection,
    }
  } catch (err) {
    if (err?.status === 409) {
      toast.error(
        'Another user saved a new version while you were editing. Please note your changes, navigate to the newest version, and try again.'
      )
    } else {
      toast.error('Failed to edit scorecard')
    }
    return null
  } finally {
    dispatch(setLoading('scorecards', false))
  }
}

export const editSectionName =
  (scorecardSid, sectionSsid, name, organizationId) => async (dispatch) => {
    dispatch(setLoading('scorecards', true))
    const nameFilter = queryString.stringify({ section_name: name })
    try {
      const newScorecard = await fetchingAPI(
        `${apiService.scorecard}/scoring/scorecards/scorecard/${scorecardSid}/section/${sectionSsid}?${nameFilter}`,
        'PATCH',
        dispatch
      )

      const newSection = newScorecard.sections.find(
        (scorecardSection) => scorecardSection.ssid === sectionSsid
      )

      await dispatch(fetchScorecardsByOrg(organizationId))
      await dispatch(setData('targetScorecardConfig', newScorecard))
      await dispatch(setData('targetScorecardSection', newSection))
      return newScorecard
    } catch (err) {
      toast.error('Failed to edit scorecard section name')
      return null
    } finally {
      dispatch(setLoading('scorecards', false))
    }
  }

export const editMeasureName =
  (scorecardSid, sectionSsid, measureSmid, name, organizationId) => async (dispatch) => {
    dispatch(setLoading('scorecards', true))
    const nameString = queryString.stringify({ measure_name: name })
    try {
      const newScorecard = await fetchingAPI(
        `${apiService.scorecard}/scoring/scorecards/scorecard/${scorecardSid}/section/${sectionSsid}/measure/${measureSmid}?${nameString}`,
        'PATCH',
        dispatch
      )

      const newSection = newScorecard.sections.find(
        (scorecardSection) => scorecardSection.ssid === sectionSsid
      )

      await dispatch(fetchScorecardsByOrg(organizationId))
      await dispatch(setData('targetScorecardConfig', newScorecard))
      await dispatch(setData('targetScorecardSection', newSection))
      return newScorecard
    } catch (err) {
      toast.error('Failed to edit scorecard measure name')
      return null
    } finally {
      dispatch(setLoading('scorecards', false))
    }
  }

export const editScorecardSettings =
  ({ ...params }) =>
  async (dispatch, getState) => {
    dispatch(setLoading('scorecards', true))
    const { min_duration_in_minutes, ...rest } = params
    const formattedParams = rest
    if (min_duration_in_minutes) {
      formattedParams.min_duration_in_seconds = min_duration_in_minutes * 60
    }
    try {
      const { scorecards } = getState()
      const { currentOrganizationId } = scorecards.data

      const updatedScorecard = await fetchingAPI(
        `${apiService.scorecard}/scoring/scorecards/scorecard/${params.sid}/settings`,
        'PATCH',
        dispatch,
        JSON.stringify(formattedParams)
      )
      await dispatch(setData('targetScorecardConfig', updatedScorecard))
      toast.success('Your scorecard has been updated')
      await dispatch(fetchScorecardsByOrg(currentOrganizationId))
    } catch (err) {
      toast.error('Failed to update scorecard settings')
    } finally {
      dispatch(setLoading('scorecards', false))
    }
  }

export const getMissingCriteria = (config_id, scorecard_config_id) => async (dispatch) => {
  dispatch(setLoading('missingCriteria', true))
  try {
    const missingCriteria = await fetchingAPI(
      `${apiService.scorecard}/scoring/scorecards/missing_criteria?config_id=${config_id}&scorecard_config_id=${scorecard_config_id}`,
      'GET',
      dispatch
    )
    await dispatch(setData('targetScorecardMissingCriteria', missingCriteria))
  } catch (err) {
    toast.error('Failed to get missing criteria')
  } finally {
    dispatch(setLoading('missingCriteria', false))
  }
}

export const removeMissingCriteria = () => async (dispatch) => {
  await dispatch(setData('targetScorecardMissingCriteria', []))
}

export const updatePlaybookVersionForScorecard = (scorecard) => async (dispatch, getState) => {
  const { scorecards } = getState()
  dispatch(setLoading('scorecards', true))
  const { currentOrganizationId } = scorecards.data

  try {
    const updatedScorecard = await fetchingAPI(
      `${apiService.scorecard}/scoring/scorecards/update_playbook_version/${scorecard.id}&requested_organization_id=${scorecard.organization_id}`,
      'GET',
      dispatch
    )
    await dispatch(setData('targetScorecardConfig', updatedScorecard))
    await dispatch(fetchScorecardsByOrg(currentOrganizationId))
    return updatedScorecard
  } catch (err) {
    toast.error('Failed to update playbook version')
    return null
  } finally {
    dispatch(setLoading('scorecards', false))
  }
}

export const createScorecardVersion = (scorecard) => async (dispatch, getState) => {
  dispatch(setLoading('scorecards', true))
  try {
    const { scorecards } = getState()
    const { currentOrganizationId } = scorecards.data
    const body = JSON.stringify({ ...scorecard })

    const updatedScorecard = await fetchingAPI(
      `${apiService.scorecard}/scoring/scorecards/scorecard/${scorecard.id}`,
      'PUT',
      dispatch,
      body
    )
    await dispatch(setData('targetScorecardConfig', updatedScorecard))
    await dispatch(fetchScorecardsByOrg(currentOrganizationId))
    return updatedScorecard
  } catch (err) {
    toast.error('Failed to create new scorecard config version')
    return null
  } finally {
    dispatch(setLoading('scorecards', false))
  }
}

export const toggleScorecardActive = (scorecard) => async (dispatch, getState) => {
  dispatch(setLoading('scorecards', true))

  try {
    const { scorecards } = getState()
    const { currentOrganizationId } = scorecards.data

    const updatedScorecard = await fetchingAPI(
      `${apiService.scorecard}/scoring/scorecards/scorecard/${scorecard.sid}/settings?toggle_active=True`,
      'PATCH',
      dispatch
    )
    await dispatch(setData('targetScorecardConfig', updatedScorecard))
    await dispatch(fetchScorecardsByOrg(currentOrganizationId))
  } catch (err) {
    toast.error('Failed to update scorecard settings')
  } finally {
    dispatch(setLoading('scorecards', false))
  }
}

export const updateScorecardSettings =
  (scorecard, settings, scorecardType = 'automated') =>
  async (dispatch, getState) => {
    dispatch(setLoading('scorecards', true))

    try {
      const { scorecards } = getState()
      const { currentOrganizationId } = scorecards.data

      const updatedScorecard = await fetchingAPI(
        `${apiService.scorecard}/scoring/scorecards/scorecard/${scorecard.sid}/settings`,
        'PATCH',
        dispatch,
        JSON.stringify(settings)
      )
      dispatch(setData('targetScorecardConfig', updatedScorecard))
      await dispatch(fetchScorecardsByOrg(currentOrganizationId, scorecardType))
    } catch (err) {
      toast.error('Failed to update scorecard settings')
    } finally {
      dispatch(setLoading('scorecards', false))
    }
  }

export const deleteScorecard =
  (scorecardId, scorecardType = 'automated') =>
  async (dispatch, getState) => {
    dispatch(setLoading('scorecards', true))

    try {
      const { scorecards } = getState()
      const { currentOrganizationId } = scorecards.data

      await fetchingAPI(
        `${apiService.scorecard}/scoring/scorecards/scorecard/${scorecardId}`,
        'DELETE',
        dispatch
      )
      await dispatch(fetchScorecardsByOrg(currentOrganizationId, scorecardType))
    } catch (err) {
      toast.error('Failed to delete scorecard')
    } finally {
      dispatch(setLoading('scorecards', false))
    }
  }

export const selectScorecard = (scorecard) => (dispatch) => {
  dispatch(setData('selectedScorecards', [scorecard]))
}

export const removeSelectedScorecard = () => (dispatch) => {
  dispatch(setData('selectedScorecards', []))
}

export const resetScorecardConfigs = () => (dispatch) => {
  dispatch(setData('scorecardConfigs', []))
}

export const resetSelectedScorecardConfig = () => (dispatch) => {
  dispatch(setData('targetScorecardConfig', {}))
}

export const setCurrentOrganizationId = (id) => (dispatch) => {
  dispatch(setData('currentOrganizationId', id))
}

export const getManualScores = () => async (dispatch, getState) => {
  dispatch(setLoading('manualScores', true))
  try {
    const { currentUser } = getState()
    const { organizationid: orgId } = currentUser
    const start = new Date(new Date().setHours(0, 0, 0, 0)).toISOString()
    const manualScores = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/manualScorecardProgress/${orgId}?start_date=${start}`,
      'GET',
      dispatch
    )

    const updatedScorecards = formatManualScorecardTableData(manualScores, orgId)

    dispatch(setData('manualScores', updatedScorecards))
  } catch (err) {
    toast.error('Failed to get manual scores')
  } finally {
    dispatch(setLoading('manualScores', false))
  }
}

export const getManualScoresByDateAndSid = (sid, dates) => async (dispatch, getState) => {
  const { currentUser, scorecards } = getState()
  const { organizationid: orgId } = currentUser
  const formattedDates = dates.map((date) => new Date(date).toISOString())
  const loadingScorecards = scorecards.data.manualScores.map((scorecard) =>
    scorecard.sid === sid
      ? {
          ...scorecard,
          loading: true,
        }
      : scorecard
  )
  dispatch(setData('manualScores', loadingScorecards))
  try {
    const manualScores = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/manualScorecardProgress/${orgId}?start_date=${formattedDates[0]}&end_date=${formattedDates[1]}&override_sid=${sid}`,
      'GET',
      dispatch
    )
    const formattedManualScorecard = formatManualScorecard(manualScores[0], orgId)
    // get state to pull the most recent version of the loaders
    // there's a potential race condition where the user could click on a different scorecard
    const { scorecards: updatedScorecards } = getState()
    const updatedManualScores = updatedScorecards.data.manualScores.map((scorecard) =>
      scorecard.sid === sid ? formattedManualScorecard : scorecard
    )
    // swap it like indiego jones
    dispatch(setData('manualScores', updatedManualScores))
  } catch (err) {
    toast.error('Failed to get manual scores')
  } finally {
    dispatch(setLoading('manualScores', false))
  }
}

export const getAllDisputes = () => async (dispatch, getState) => {
  dispatch(setLoading('disputes', true))
  try {
    const { currentUser } = getState()
    const { organizationid: orgId } = currentUser
    const disputes = await fetchingAPI(
      `${apiService.scorecard}/disputes/all?requested_organization_id=${orgId}`,
      'GET',
      dispatch
    )
    const formattedDisputes = formatDisputes(disputes)
    dispatch(setData('disputes', formattedDisputes))
  } catch (err) {
    toast.error('Failed to get disputes')
  } finally {
    dispatch(setLoading('disputes', false))
  }
}

export const fetchDispositionsByOrg = (organizationId) => async (dispatch) => {
  dispatch(setLoading('dispositions', true))

  try {
    const dispositions = await fetchingAPI(
      `${apiService.reporting}/api/dispositions?organization_id=${organizationId}`,
      'GET',
      dispatch
    )
    const dispoOptions = dispositions
      .map(({ name }) => ({
        value: name,
        label: name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
    dispatch(setData('dispositions', dispoOptions))
  } catch (err) {
    toast.error('Failed to fetch dispositions')
  } finally {
    dispatch(setLoading('dispositions', false))
  }
}
