import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconChevronDown, IconChevronRight, IconSparkles } from '@tabler/icons-react'
import { Popup, Button, Dropdown, Divider } from 'semantic-ui-react'
import { Formik } from 'formik'
import classNames from 'classnames'
import pluralize from 'pluralize'
import { truncate } from 'lodash'
import { toast } from 'react-toastify'

import { Pill } from '@/components/pills/Pill'
import {
  setTemporaryCriteria,
  editScorecardConfig,
  setFocusArea,
} from '@/reducers/qa-copilot/qa-copilot.redux'
import { openModal } from '@/reducers/ui/ui.redux'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'

import { QACopilotScorecardCriteriaPromptForm } from '@/views/QACopilot/forms/QACopilotScorecareCriteriaPromptForm'
import { addPrompt, setPrompts, setTemporaryPrompt } from '@/reducers/prompts/prompts.redux'
import { updatePromptHelper } from '@/reducers/prompts/prompts.helpers'
import {
  criteriaTypeOptions,
  createCriteriaHelper,
  updateCriteriaHelper,
  criteriaPromptValidationSchema,
  getBooleanValue,
} from '../QACopilot.helpers'
import { QACopilotDeleteCriteriaModal } from '../modals/QACopilotDeleteCriteriaModal'

export const QACopilotScorecardCriteriaPrompt = ({ prompt, criteria, section }) => {
  const dispatch = useDispatch()
  const { scorecardConfig } = useSelector((state) => state.qaCopilot)
  const { prompts, modelOptions } = useSelector((state) => state.prompts)
  const { organizationid: organizationId, prompt_editor: isPromptEditor } = useSelector(
    (state) => state.currentUser
  )
  const isTemporaryCriteria = criteria.is_new && criteria.ssid === section.ssid
  const [editMode, setEditMode] = useState(isTemporaryCriteria)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const criteriaType = criteriaTypeOptions.find((option) => option.value === criteria.criteria_type)
  const criteriaTypeText = criteriaType?.text
  const deleteModalId = `qaCopilot/deleteCriteria-${criteria.scid}`
  const criteriaPointsModalId = 'qaCopilot/criteriaPoints'
  const isBaltoAdmin = organizationId === 1
  const canEditPrompts = isBaltoAdmin || isPromptEditor
  const promptsEnabled =
    canEditPrompts && (criteria.criteria_type === 'ai' || criteria.manual_timestamp_markers)
  const promptTestsEnabled = canEditPrompts && criteria.criteria_type === 'ai'

  const criteriaInitialValues = {
    name: criteria.name || '',
    description: criteria.description || '',
    criteria_type: criteria.criteria_type || 'ai',
    weight: criteria.weight || 1,
    must_be_present: getBooleanValue(criteria.must_be_present, 'true'),
    user_prompt: prompt.user_prompt || scorecardConfig.shell_prompt || '',
    first_x_minutes: prompt.first_x_minutes || '',
    last_x_minutes: prompt.last_x_minutes || '',
    side: prompt.side || 'both',
    model: prompt.model || 'gpt-4o',
    prompt_id: prompt.id || null,
    system_prompt: prompt.system_prompt || '',
    max_response_tokens: prompt.max_response_tokens || null,
    call_id: '',
    expected_response: '',
    manual_timestamp_markers: getBooleanValue(criteria.manual_timestamp_markers, 'false'),
    add_to_coaching_playlist_on_miss: getBooleanValue(
      criteria.add_to_coaching_playlist_on_miss,
      'false'
    ),
  }

  const modelTypeDisplayName = modelOptions.find((option) => option.value === prompt.model)?.label

  const handleEdit = (focusArea) => {
    setEditMode(true)
    dispatch(setFocusArea(focusArea))
  }

  const handleOpenCriteriaPointsModal = () => {
    dispatch(openModal(criteriaPointsModalId))
  }

  const handleOpenDeleteModal = () => {
    dispatch(openModal(deleteModalId))
  }

  const handleCancel = () => {
    if (isTemporaryCriteria) {
      dispatch(setTemporaryCriteria(null))
      dispatch(setTemporaryPrompt(null))
    } else {
      setEditMode(false)
    }
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(prompt.user_prompt)
    toast.success('Copied!')
  }

  const handleUpdateCriteria = (values) => {
    const newPrompt = {
      ...prompt,
      user_prompt: values.user_prompt || '',
      first_x_minutes: values.first_x_minutes || null,
      last_x_minutes: values.last_x_minutes || null,
      side: values.side,
      model: values.model,
      scid: criteria.scid,
    }

    const newCriteria = {
      ...criteria,
      name: values.name || criteria.name,
      description: values.description || criteria.description,
      criteria_type: values.criteria_type || criteria.criteria_type,
      weight: values.weight || criteria.weight,
      must_be_present: values.must_be_present === 'true',
      manual_timestamp_markers: values.manual_timestamp_markers === 'true',
      add_to_coaching_playlist_on_miss: values.add_to_coaching_playlist_on_miss === 'true',
    }

    // Only balto admin can edit prompts (prompt fields are only visible to admin anyway)
    if (isTemporaryCriteria) {
      if (canEditPrompts) {
        dispatch(addPrompt(newPrompt))
        dispatch(setTemporaryPrompt(null))
      }
      dispatch(
        editScorecardConfig(createCriteriaHelper(scorecardConfig, newCriteria, section.scid))
      )
      dispatch(setTemporaryCriteria(null))
    } else {
      if (canEditPrompts) {
        const updatedPrompts = updatePromptHelper(prompts, newPrompt)
        dispatch(setPrompts(updatedPrompts))
      }
      dispatch(editScorecardConfig(updateCriteriaHelper(scorecardConfig, newCriteria)))
      setEditMode(false)
    }
  }

  if (editMode) {
    return (
      <Formik
        initialValues={criteriaInitialValues}
        validationSchema={criteriaPromptValidationSchema}
        onSubmit={handleUpdateCriteria}
        validateOnMount
      >
        {({ handleSubmit, values, errors, setFieldValue }) => {
          return (
            <QACopilotScorecardCriteriaPromptForm
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
              values={values}
              errors={errors}
              isTemporaryCriteria={isTemporaryCriteria}
              setFieldValue={setFieldValue}
            />
          )
        }}
      </Formik>
    )
  }

  return (
    <>
      <section
        id={criteria.scid}
        className={classNames('qa-copilot-editor__scorecard-criteria', { collapsed: isCollapsed })}
      >
        <header onClick={() => setIsCollapsed(!isCollapsed)}>
          <div className="flex small-gap vertical-center">
            <Button
              type="button"
              icon
              basic
              compact
              className="svg-button"
              style={{ marginRight: 0 }}
            >
              {isCollapsed ? <IconChevronRight /> : <IconChevronDown />}
            </Button>
            <ConditionalTooltip
              condition={criteria.name?.length > 60}
              content={criteria.name}
              tooltipProps={{ position: 'bottom left' }}
            >
              <h2>{truncate(criteria.name, { length: 60 })}</h2>
            </ConditionalTooltip>
          </div>
          <div className="flex gap vertical-center">
            <Pill
              magical={criteriaType?.value === 'ai'}
              informative={criteriaType?.value === 'manual'}
              icon
            >
              {criteriaType?.value === 'ai' && (
                <div className="flex primary-color">
                  <IconSparkles className="icon-svg" />
                </div>
              )}
              <div>
                <strong>{criteriaTypeText}</strong>
              </div>
            </Pill>
            <Popup
              position="top center"
              inverted
              content="Click to add points"
              trigger={
                <Button
                  secondary
                  onClick={(event) => {
                    event.stopPropagation()

                    handleOpenCriteriaPointsModal()
                  }}
                >
                  {pluralize('Point', criteria.weight, true)}
                </Button>
              }
            />
            <Dropdown
              item
              direction="right"
              icon="ellipsis vertical"
              button
              className="icon minimal-button hidden"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={(event) => {
                    event.stopPropagation()

                    handleEdit('')
                  }}
                >
                  Edit Criterion
                </Dropdown.Item>
                <Dropdown.Item
                  className="danger"
                  onClick={(event) => {
                    event.stopPropagation()

                    handleOpenDeleteModal()
                  }}
                >
                  Delete Criterion
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </header>

        {!isCollapsed && (
          <>
            <div className="qa-copilot-editor__scorecard-criteria--content">
              <div className="qa-copilot-editor__scorecard-criteria--description">
                {criteria.description ? (
                  <p>{criteria.description}</p>
                ) : (
                  <p className="muted-text">
                    No assessment guidelines have been written yet. Edit to add assessment
                    guidelines to this criterion.
                  </p>
                )}
              </div>
              {promptsEnabled && (
                <div className="qa-copilot-editor__scorecard-criteria--ai-prompt copilot-prompt">
                  <div className="header-container">
                    <div className="header-icon">
                      <IconSparkles className="icon-svg" />
                      <h4>{promptTestsEnabled ? 'Copilot Prompt' : 'Timestamp Marker'}</h4>
                    </div>
                    <div className="header-buttons">
                      {promptTestsEnabled && (
                        <Button secondary onClick={() => handleEdit('callid')}>
                          Test Prompt
                        </Button>
                      )}
                      <Button secondary onClick={() => handleEdit('prompt')}>
                        Edit Prompt
                      </Button>
                      <Button color="black" onClick={handleCopy}>
                        Copy Prompt
                      </Button>
                    </div>
                  </div>
                  {prompt.user_prompt ? (
                    <p>{prompt.user_prompt}</p>
                  ) : (
                    <p className="muted-text">No AI prompt has been written yet.</p>
                  )}
                  <Divider />
                  {criteria.criteria_type === 'ai' && (
                    <p>Expected Response: {criteria.must_be_present ? 'Yes' : 'No'}</p>
                  )}
                  {prompt.first_x_minutes && (
                    <p>
                      Uses{' '}
                      {prompt.first_x_minutes &&
                        `${pluralize(
                          'minute',
                          prompt.first_x_minutes,
                          true
                        )} at the start of the call`}
                    </p>
                  )}
                  {prompt.last_x_minutes && (
                    <p>
                      Uses{' '}
                      {prompt.last_x_minutes &&
                        `${pluralize(
                          'minute',
                          prompt.last_x_minutes,
                          true
                        )} at the end of the call`}
                    </p>
                  )}
                  <p>Uses {prompt.side === 'agent' ? 'agent side only' : 'both sides'}</p>
                  <p>Uses {modelTypeDisplayName}</p>
                </div>
              )}
            </div>
            <footer>
              <Button type="button" secondary onClick={() => handleEdit('')}>
                Edit
              </Button>
            </footer>
          </>
        )}
      </section>

      <QACopilotDeleteCriteriaModal
        criteria={criteria}
        isTemporaryCriteria={isTemporaryCriteria}
        setEditMode={setEditMode}
      />
    </>
  )
}
