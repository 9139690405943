import moment from 'moment'
import queryString from 'query-string'
import { isEmpty, isNaN, isNil, startCase, truncate } from 'lodash'
import pluralize from 'pluralize'

export const formatCallDuration = (callDuration) => {
  // All nil values should be "1"
  if (callDuration === '' || isNaN(callDuration) || isNil(callDuration)) {
    return '1'
  }

  // If valid number, round and convert to string
  const callDurationInt = parseInt(callDuration, 10)
  if (!isNaN(callDurationInt)) {
    return callDurationInt.toString()
  }

  return '1'
}

export const getFilterQueryString = (filter = {}, section) => {
  const categories = ['deck', 'postcall'].includes(section)
    ? filter[`${section}Categories`]?.map(({ value }) => value)
    : []
  const startDate = filter.startDate ? moment(filter.startDate).startOf('day').format() : undefined
  const endDate = filter.endDate ? moment(filter.endDate).endOf('day').format() : undefined

  const queryStringObject = {
    organization: filter.organization,
    organization_id: filter.organization,
    agents: filter.agents?.map(({ value }) => value),
    playbooks: filter.playbooks?.map(({ value }) => value),
    tags: filter.tags?.map(({ value, tag_cat }) => `${tag_cat}:${value}`),
    categories,
    call_duration: formatCallDuration(filter.callDuration),
    max_call_duration: !filter.maxCallDuration ? 0 : filter.maxCallDuration,
    include_managers: filter.includeManagers,
    type: filter.challengeType?.map(({ value }) => value),
    status: filter.challengeStatus?.map(({ value }) => value),
    start_date: startDate,
    end_date: endDate,
    sections: filter.sections,
    is_win: filter.isWin,
    dispositions: filter.dispositions?.map(({ value }) => value),
  }

  // new reporting service uses "organization_id" instead of "organization" once everything is moved deprecate "organization"
  // new reporting service does not tolerate nulls/empty string for these if not passed (not org 1 doesn't provide)
  if (!filter.organization) {
    delete queryStringObject.organization
    delete queryStringObject.organization_id
  }

  if (filter.isWin === null || section === 'usage' || section === 'csv') {
    delete queryStringObject.is_win
  }

  if (isEmpty(filter.dispositions || section === 'usage' || section === 'csv')) {
    delete queryStringObject.dispositions
  }

  const formattedQueryString = queryString.stringify(queryStringObject)

  return formattedQueryString
}

export const getOrganizationOptions = (organizations = [], isTruncated = false) => {
  return organizations
    .map((organization) => ({
      value: organization.id,
      label: isTruncated ? truncate(organization.name, { length: 35 }) : organization.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

export const getHierarchyOrganizationOptions = (organizations = [], isTruncated = false) => {
  return organizations
    .map(({ name, organization_id }) => ({
      value: organization_id,
      label: isTruncated ? truncate(name, { length: 35 }) : name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

export const getScorecardOptions = (scorecards) => {
  if (scorecards) {
    return scorecards.map((scorecard) => ({
      value: scorecard.id,
      label: scorecard.name,
      sid: scorecard.sid, // Needed for aggregate endpoint
    }))
  }
  return []
}

export const getSectionOptions = (selectedScorecard) => {
  if (selectedScorecard) {
    return selectedScorecard.sections?.map((section) => ({
      value: section.id,
      label: section.name,
    }))
  }
  return []
}

export const getNestedOptions = (selectedOption, sectionName) => {
  const nestedOption = []
  if (selectedOption?.length > 0) {
    selectedOption.forEach((section) => {
      const nestedObject = {
        label: section.name,
        options: [],
      }
      const formatObject = section[sectionName]?.map((measure) => {
        return {
          value: measure.id,
          label: measure.name,
        }
      })
      nestedObject.options.push(...formatObject)
      nestedOption.push(nestedObject)
    })
  }
  return nestedOption
}

export const getTagOptions = (tags) => {
  return tags
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((tag) => ({
      label: tag.name,
      value: tag.id,
      tag_cat: tag.tag_category_id,
    }))
}

export const getTagsWithCategories = (tags, tagCategories) => {
  const tagsWithCategories = []

  // Created grouped tag categories
  tagCategories.forEach((cat) => {
    tagsWithCategories.push({
      label: cat.name,
      options: getTagOptions(tags.filter((tag) => tag.tag_category_id === cat.id)),
    })
  })

  // Add uncategorized tags
  tagsWithCategories.push({
    label: 'Uncategorized',
    options: getTagOptions(tags.filter((tag) => !tag.tag_category_id)),
  })

  return tagsWithCategories
}

export const getDateFormat = (date, groupByValue) => {
  switch (groupByValue) {
    case 'week':
      return `${moment.utc(date).format('M/DD')} - ${moment
        .utc(date)
        .add(6, 'days')
        .format('M/DD')}`
    case 'month':
      return moment.utc(date).format('MMMM')
    default:
      return moment.utc(date).format('M/DD')
  }
}

export function getPercentNumerical(numerator, denominator) {
  if (denominator > 0) {
    return Math.round(Math.abs((numerator / denominator) * 100))
  }

  return 0
}

export const formatPercent = (value, decimals, lessThanOne = false) => {
  if (isNaN(value) || value === 0) {
    return '0%'
  }

  if (decimals) {
    if (value < 1 && lessThanOne) {
      return '< 1%'
    }
    return `${parseFloat(value).toFixed(decimals)}%`
  }

  return `${Math.round(Math.abs(value))}%`
}

export const formatSeconds = (seconds) => {
  const mins = Math.floor(seconds / 60)
  const secs = Math.floor(seconds - mins * 60)

  return `${mins}m ${secs}s`
}

export const getChecklistPercent = (checklistData) => {
  if (checklistData.length) {
    const checklistTotals = checklistData.reduce((a, b) => {
      // eslint-disable-next-line no-return-assign, no-param-reassign
      Object.keys(b).map((c) => (a[c] = (a[c] || 0) + b[c]))

      return a
    }, {})

    if (Object.keys(checklistTotals).length > 0 && checklistTotals.total > 0) {
      return getPercentNumerical(checklistTotals.items, checklistTotals.total)
    }
  }

  return 0
}

export function explodeItemsByUser(items = [], users = []) {
  const agents = users.reduce((acc, item) => {
    const totalCalls = item?.total_calls || 0
    const totalUsedEvents = item?.total_used_events || 0
    const totalPossibleEvents = item?.total_possible_events || 0

    acc[item.id] = {
      first_name: item?.first_name,
      last_name: item?.last_name,
      ...(totalCalls !== 0 && {
        total_calls_count: totalCalls,
      }),
      ...(totalCalls !== 0 && {
        average_completion_percent: getPercentNumerical(totalUsedEvents, totalPossibleEvents),
      }),
    }
    return acc
  }, {})

  return items.flatMap((item) =>
    item.users.map((userEntry) => ({
      item: item.item,
      count: userEntry?.num_calls_with_event || 0,
      total: userEntry?.total_calls || 0,
      user: agents[userEntry.id],
    }))
  )
}

export function truncateString(string, num) {
  if (string.length > num) {
    // eslint-disable-next-line prefer-template
    return string.slice(0, num - 3) + '...'
  }
  return string
}

export const truncateAgentName = (agentName) => {
  if (agentName) {
    const agentFirstInitial = agentName.split(' ')[0].charAt(0)
    return `${agentFirstInitial}. ${agentName.split(' ')[1]}`
  }
  return false
}

export const compareToPreviousRange = (currentData, prevData) => {
  if (!prevData) {
    return null
  }

  if (currentData === prevData) {
    return 0
  }

  const result = (Math.abs(currentData - prevData) / prevData) * 100
  const isDecimal = Math.ceil(result) !== result

  return isDecimal ? result.toFixed(2) : result
}

export const findPreviousDateRangeFromCurrentFilters = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return [startDate, endDate]
  }

  const parsedStartDate = new Date(startDate)
  const parsedEndDate = new Date(endDate)
  const numDays = parsedEndDate - parsedStartDate || 86400000 // number of milliseconds in a day, because default is one day
  const newStartDate = new Date(parsedStartDate - numDays)
  const newEndDate = new Date(parsedEndDate - numDays)

  return [newStartDate, newEndDate]
}

export const getCardVariant = (currentData, prevData) => {
  if (currentData > prevData) {
    return 'positive'
  }

  if (currentData < prevData) {
    return 'negative'
  }

  return 'neutral'
}

export const getCardVariantReverse = (currentData, prevData) => {
  if (currentData < prevData) {
    return 'positive'
  }

  if (currentData > prevData) {
    return 'negative'
  }

  return 'neutral'
}

const getAvg = (arr) => {
  const total = arr.reduce((acc, c) => acc + c, 0)
  return total / arr.length
}

const getSum = (arr) => {
  return arr.reduce((acc, c) => acc + c, 0)
}
// https://github.com/heofs/trendline stolen from this
// data science confirmed math is good
export const createTrend = (data, xKey, yKey) => {
  const xData = data.map((value) => value[xKey])
  const yData = data.map((value) => value[yKey])

  const xMean = getAvg(xData)
  const yMean = getAvg(yData)

  const xMinusxMean = xData.map((val) => val - xMean)
  const yMinusyMean = yData.map((val) => val - yMean)

  const xMinusxMeanSq = xMinusxMean.map((val) => val ** 2)

  const xy = []
  for (let x = 0; x < data.length; x++) {
    xy.push(xMinusxMean[x] * yMinusyMean[x])
  }

  const xySum = getSum(xy)

  const slope = xySum / getSum(xMinusxMeanSq)
  const yStart = yMean - slope * xMean

  return {
    slope,
    yStart,
    calcY: (xValue) => yStart + slope * xValue,
  }
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const formatTime = (time, formatString = 'MMMM D, YYYY [at] h:mm A') => {
  return moment.utc(time).local().format(formatString)
}

export const paramPusher = (filterValues, params, data, key, property = 'value') => {
  if (!isEmpty(filterValues[data])) {
    filterValues[data].forEach((item) => params.append(key, item[property]))
  }
}

export const sharedScorecardParams = (
  filterTypes,
  filterValues,
  params,
  orgId,
  isCopilot = false
) => {
  if (typeof orgId === 'number') {
    params.set('org_id', orgId)
    params.set('requested_organization_id', orgId)
  }

  if (isCopilot) {
    params.set('scorecard_type', 'copilot')
  }

  if (filterValues.scorecardType) {
    params.set('scorecard_type', filterValues.scorecardType)
  }

  if (!isNil(filterValues.isWin)) {
    params.set('is_win', filterValues.isWin)
  }

  if (!isNil(filterValues.scoredStatus)) {
    params.set('scored_status', filterValues.scoredStatus)
  }

  if (filterValues.startDate) {
    params.set('start_date', moment(filterValues.startDate).utc().format())
  }

  if (filterValues.endDate) {
    params.set('end_date', moment(filterValues.endDate).utc().format())
  }

  filterTypes.forEach((filter) =>
    paramPusher(filterValues, params, filter.data, filter.key, filter.value)
  )
}

export const getPlaceholderContent = (data, loading, type) => {
  if (loading[type]) {
    return { placeholder: 'Loading...' }
  }

  if (!isEmpty(data[type])) {
    return { placeholderPill: `All ${startCase(pluralize(type))}` }
  }

  return { placeholder: 'None available' }
}

export const getRangeInDays = (startDate, endDate) => {
  const duration = moment.duration(moment(endDate).diff(moment(startDate)))
  const days = Math.floor(duration.asDays())

  return days
}

export const getTickValuesForDateRange = (days) => {
  if (days < 7) {
    return 'every day'
  }

  if (days < 31) {
    return 'every 5 days'
  }

  if (days < 61) {
    return 'every week'
  }

  return 'every week'
}

export const convertAgentNameToInitials = (agentName) => {
  return agentName
    .match(/(\b\S)?/g)
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toUpperCase()
}

export const formatTimeForAudio = (secs) => {
  const minutes = Math.floor(secs / 60)
  const seconds = Math.floor(secs % 60)
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`

  return `${minutes}:${returnedSeconds}`
}

export const audioErrorSwitch = (errorCode) => {
  // https://html.spec.whatwg.org/multipage/media.html#dom-media-error-dev
  switch (errorCode) {
    case 1:
      console.error('Audio loading aborted by user')
      break
    case 2:
      console.error('Audio loading interrupted by a network error')
      break
    case 3:
      console.error(
        'Audio loading interrupted during decoding. The audio was determined to be unusable'
      )
      break
    case 4:
      console.error(
        "The audio URL is not valid. Either this call is too new and a file doesn't exist, or there is a problem with existing the audio file."
      )
      break
    default:
      console.error('Unknown audio player error, please refresh your page.')
      break
  }
}

export const showFirstResult = (arr = [], defaultValue = '0 selected') => {
  if (arr.length === 1) {
    return arr[0].label
  }

  if (!isEmpty(arr)) {
    return `${arr.length} selected`
  }

  return defaultValue
}

export const showNestedResult = (arr = []) => (!isEmpty(arr) ? `${arr.length} selected` : 'N/A')

export const getUnixMs = (date) => new Date(date).valueOf() // Convert date to Unix milliseconds
