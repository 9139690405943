import moment from 'moment'
import { isNil, groupBy, sortBy } from 'lodash'

import { chartColors, notApplicableChartColor } from '../../../utils/constants'
import { getDateFormat, formatPercent, getPercentNumerical } from '../../../utils/helpers'

export const fullName = (user) => `${user.last_name}, ${user.first_name}`
export const formatFullName = (name) => name.split(', ').reverse().join(' ')
export const calculateDuration = (durationInMs) => {
  const duration = durationInMs > 0 ? Math.round(Math.abs(durationInMs / 60000)) : 0

  return `${duration} minute${duration !== 1 ? 's' : ''}`
}

function calculatePercent(item, isWin) {
  if (isWin) {
    return item.count > 0 ? (item.win_count / item.count) * 100 : 0
  }
  return item.total > 0 ? (item.count / item.total) * 100 : 0
}

export function groupAndFormatChecklistUsage(data, groupByValue, isPercent) {
  // Group data into an object with the keys as the axis date to use
  const groupedByRange = groupBy(data, (result) => {
    return moment(result.date).utc().startOf(groupByValue)
  })

  // Add all values from the daily amount
  return Object.entries(groupedByRange)
    .sort((a, b) => new Date(a[0]) - new Date(b[0]))
    .map(([date, values]) => {
      return {
        ...values.reduce((acc, obj) => {
          for (const [propKey, propVal] of Object.entries(obj)) {
            if (!acc[propKey]) {
              acc[propKey] = 0
            }
            acc[propKey] += propVal
          }

          // Calculate percent
          acc.percent = acc.total > 0 ? (acc.items / acc.total) * 100 : 0

          // Have to use the same key unless this issue gets fixed https://github.com/plouc/nivo/issues/1652
          acc.dataKey = isPercent ? acc.percent : acc.items

          return acc
        }, {}),
        date: getDateFormat(new Date(date), groupByValue),
      }
    })
}

// Have to use the same key unless this issue gets fixed https://github.com/plouc/nivo/issues/1652
export function formatTopData(data, isPercent) {
  if (isPercent) {
    return data.by_percent
      .map((item, i) => ({
        ...item,
        dataKey: item.total > 0 ? (item.count / item.total) * 100 : 0,
        color: chartColors[i % chartColors.length],
      }))
      .sort((a, b) => b.dataKey - a.dataKey)
  }

  return data.by_count
    .map((item, i) => ({
      ...item,
      dataKey: item.count,
      color: chartColors[i % chartColors.length],
    }))
    .sort((a, b) => b.dataKey - a.dataKey)
}

export function groupAndFormatItemsByDate(
  itemAccessor,
  items,
  itemsByDate,
  groupByValue,
  isPercent,
  isWin
) {
  // Should probably make this function return { legendData, chartData } since the legend and Chart
  // require different data

  // Loop through all checklist items except hidden ones
  return items.map((item, i) => {
    // Filter out the data that isn't a checklist item
    const filteredItems = itemsByDate.filter(
      (obj) => obj[itemAccessor] === (item[itemAccessor] || item)
    )
    // Group the filtered items by date range criteria
    const groupedByRange = groupBy(filteredItems, (result) => {
      return moment(result.date).utc().startOf(groupByValue)
    })

    const aggregateData = Object.entries(groupedByRange)
      .sort((a, b) => new Date(a[0]) - new Date(b[0]))
      .map(([date, values]) => {
        // Set up the object with empty values for total and count
        const item = {
          total: 0,
          count: 0,
          [itemAccessor]: values[0][itemAccessor],
          date: getDateFormat(new Date(date), groupByValue),
          win_count: 0,
        }

        // Reduce the counts and totals for different group aggregations
        for (const val of values) {
          item.count += val.count
          item.win_count += val.win_count
          item.total += val.total
        }

        const dataAccessor = isWin ? 'win_count' : 'count'

        // Calculate percent
        item.percent = calculatePercent(item, isWin)
        item.y = isPercent ? item.percent : item[dataAccessor]
        item.x = item.date

        return item
      })

    return {
      id: item[itemAccessor] || item,
      color: chartColors[i % chartColors.length],
      win: item.win,
      data: aggregateData,
    }
  })
}

export function groupAndFormatCountByDate(items, groupByValue) {
  const groupedByRange = groupBy(items, (result) => {
    return moment(result.date).utc().startOf(groupByValue)
  })

  // Add all values from the daily amount
  return Object.entries(groupedByRange)
    .sort((a, b) => new Date(a[0]) - new Date(b[0]))
    .map(([date, values]) => {
      return {
        ...values.reduce((acc, obj) => {
          for (const [propKey, propVal] of Object.entries(obj)) {
            if (!acc[propKey]) {
              acc[propKey] = 0
            }
            acc[propKey] += propVal
          }
          return acc
        }, {}),
        date: getDateFormat(new Date(date), groupByValue),
      }
    })
}

export function groupAndFormatItemsByUser(itemAccessor, itemsByUser, isPercent) {
  const data = Object.values(
    itemsByUser.reduce((acc, item) => {
      const user = fullName(item.user)

      if (!acc[user]) acc[user] = {}

      acc[user] = {
        user,
        ...acc[user],
        ...item.user,
        [item[itemAccessor]]: isPercent ? getPercentNumerical(item.count, item.total) : item.count,
      }

      return acc
    }, {})
  )

  const sortedUsers = data.sort((a, b) => a.user.localeCompare(b.user))

  return sortedUsers
}

export function formatAverageUsageByUser(itemAccessor, items, itemsByUser) {
  const winItems = items.filter((item) => item.win === 'true')

  const userAverages = []
  itemsByUser.forEach((item) => {
    const userFullName = fullName(item.user)
    const found = userAverages.some((el) => el.user === userFullName)
    if (!found) {
      let winsCount = 0
      let winsTotal = 0

      winItems.forEach((winItem) => {
        const userWins = itemsByUser.filter((i) => {
          return i[itemAccessor] === winItem[itemAccessor] && fullName(i.user) === userFullName
        })

        userWins.forEach((w) => {
          winsCount += w.count
          winsTotal += w.total
        })
      })
      userAverages.push({
        user: userFullName,
        average_completion_percent: item.user.average_completion_percent,
        average_win_percent: (winsCount / winsTotal) * 100,
        average_completion_percent_color: '#4795EC',
        average_win_percent_color: '#02C9B8',
      })
    }
  })

  userAverages.sort((a, b) => a.user.localeCompare(b.user))
  return userAverages
}

export function getUserTableData(
  itemAccessor,
  items,
  itemsByUser,
  section,
  hiddenItems,
  isPercent
) {
  let columns = [
    {
      label: 'User',
      accessor: 'user',
      noWrap: true,
      sticky: true,
      format: (v) => formatFullName(v),
    },
  ]

  if (section === 'checklist') {
    columns.push({ label: 'Calls Count', accessor: 'total_calls_count' })
    columns.push({
      label: 'Average Completion %',
      accessor: 'average_completion_percent',
      format: (v) => formatPercent(v),
    })
  }

  // Deck items come as string[], checklist items come as object[] which is why all the || are there
  columns = [
    ...columns,
    ...items
      .filter((item) => !hiddenItems.has(item[itemAccessor]))
      .map((item) => {
        return {
          accessor: item[itemAccessor],
          label: item[itemAccessor],
          color: item.color,
          format: (v) => {
            if (isNil(v)) {
              return 'N/A'
            }
            return isPercent ? formatPercent(v) : Number(v).toLocaleString()
          },
        }
      }),
  ]

  return { rows: itemsByUser, columns }
}

export function groupCallDetails(columns, section) {
  const groupedData = {}

  columns.forEach((call) => {
    const { analysis, transcript, date, user } = call
    const { items } = call
    const userFullName = `${user.first_name} ${user.last_name}`

    if (analysis && (items.length > 0 || Object.keys(items).length > 0)) {
      Object.keys(items).forEach((item) => {
        const transcriptSnippetArray = []
        const triggerTimeArray = []

        analysis.forEach(({ subcategory, time, context }) => {
          if (context.includes(section) && subcategory === item) {
            const convoSnippet =
              transcript && transcript.length > 0
                ? transcript.filter((block) => block.time > time - 20 && block.time < time + 20)
                : []
            if (convoSnippet.length > 0) {
              transcriptSnippetArray.push(convoSnippet)
              triggerTimeArray.push(time)
            }

            groupedData[`${call.call_id}-${subcategory}`] = {
              call_id: call.call_id,
              item: subcategory,
              snippets: transcriptSnippetArray,
              triggerTimes: triggerTimeArray,
              voipCallId: call.voip_call_id || '',
              userFullName,
              date,
            }
          }
        })
      })
    }
  })

  return groupedData
}

export function formatItemsByCall(rows, columns, section) {
  const formatted = columns.map((column) => {
    return {
      call_id: column.call_id,
      label: {
        name: `${column.user.first_name} ${column.user.last_name}`,
        date: moment.utc(column.date).local().format('MMM Do, h:mm A'),
        duration: calculateDuration(column.duration),
        voip_call_id: column.voip_call_id,
      },
      items: rows
        .filter((row, index) => index < 25)
        .map((row) => ({
          item: section === 'checklist' ? row.item : row,
          occurred: Object.entries(column.items).reduce((acc, [key, value]) => {
            const item = section === 'checklist' ? row.item : row

            if (key === item) {
              acc = value
            }

            return acc
          }, 0),
        }))
        .map((row, rowIndex) => ({
          ...row,
          color: row.occurred
            ? chartColors[rowIndex % chartColors.length]
            : notApplicableChartColor,
        })),
    }
  })

  return formatted
}

export function getDynamicPromptAnalysisWinRateCsvData(data) {
  const rows = []

  data.deckWinRate.forEach((d) => {
    d.items.forEach((di) => {
      rows.push({
        dp_category: d.category,
        dp: di.item,
        dp_response: '',
        count: di.count,
        win_rate: formatPercent(getPercentNumerical(di.win_count, di.count)),
      })
      di.entries.forEach((dli) => {
        rows.push({
          dp_category: d.category,
          dp: di.item,
          dp_response: dli.item,
          count: dli.count,
          win_rate: formatPercent(getPercentNumerical(dli.win_count, dli.count)),
        })
      })
    })
  })

  return rows
}

// Deck items come as string[], checklist items come as object[]
// Should probably refactor this on the BE at some point for consistency
// Also checklist items care about order and decks don't
export function getItemsWithColors(section, data, itemAccessor) {
  if (section === 'checklist') {
    return data[`${section}Items`].map((item) => ({
      ...item,
      color: chartColors[(item.order - 1) % chartColors.length],
    }))
  }

  const list = data[`${section}Items`].map((item, i) => ({
    [itemAccessor]: item,
    color: chartColors[i % chartColors.length],
  }))

  return sortBy(list, (o) => o[itemAccessor])
}

export function getSectionLabel(section) {
  let sectionLabel = section[0].toUpperCase() + section.slice(1)
  if (section === 'notifications') {
    sectionLabel = `${sectionLabel.slice(0, -1)}`
  } else if (section === 'deck') {
    sectionLabel = 'Dynamic Prompt'
  } else {
    sectionLabel += ' Item'
  }
  return sectionLabel
}
