import React from 'react'
import { Button } from 'semantic-ui-react'

import './SegmentedControl.scss'

export const SegmentedControl = ({ items = [], ...props }) => {
  return (
    <div className="segmented-control" {...props}>
      {items.map((item) => {
        return (
          <Button
            key={item.accessor}
            className="svg-button"
            icon
            secondary
            active={item.active}
            compact
          >
            {item.icon}
            {item.label}
          </Button>
        )
      })}
    </div>
  )
}
