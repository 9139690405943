import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CoachingNotes } from '@/views/Calls/components/CoachingNotes'

import {
  fetchCoachingCommentsByCall,
  addCoachingComment,
  updateCoachingComment,
} from '@/reducers/playlists/playlists.actions'
import { setPlaylistCallComments } from '@/reducers/playlists/playlists.redux'

export const CommentEditorContainer = ({ callId }) => {
  const dispatch = useDispatch()
  const { callComments } = useSelector((state) => state.playlists)

  useEffect(() => {
    dispatch(fetchCoachingCommentsByCall(callId))
    return () => {
      dispatch(setPlaylistCallComments([]))
    }
  }, [])

  const addComment = (comment, callId) => {
    dispatch(addCoachingComment(comment, callId))
  }

  const updateComment = (comment, commentId, callId) => {
    dispatch(updateCoachingComment(comment, commentId, callId))
  }

  return (
    <CoachingNotes
      callId={callId}
      callComments={callComments}
      addComment={addComment}
      updateComment={updateComment}
      showHeader={false}
    />
  )
}
