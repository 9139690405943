import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Field, FieldArray } from 'formik'
import { Button } from 'semantic-ui-react'
import { IconTrash } from '@tabler/icons-react'

import { ConditionalTooltip } from '@/components/ConditionalTooltip'
import { TextArea, TextField } from '@/components/forms/formik'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import { Drag, Drop, DragAndDrop } from '@/components/dragDrop'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { buildPrompt } from '../helpers'

export const RowEditorForm = ({ setFieldValue, values, flags }) => {
  const { rtnPrompt } = useSelector((state) => state.playbook)
  const [resetFields, setResetFields] = useState([])
  const [showResetModal, setShowResetModal] = useState(false)

  useEffect(() => {
    if (rtnPrompt?.user_prompt) {
      const promptArr = rtnPrompt?.user_prompt.split('\n\n')[1].split('\n')

      const keyVals = promptArr.map((prompt) => {
        const split = prompt.split(':')
        const promptKey = split[0]
        const promptValue = split.slice(1, split.length).join(':').trim()

        return { key: promptKey, value: promptValue }
      })

      setFieldValue('prompt_fields', keyVals)
      setFieldValue('user_prompt', buildPrompt(keyVals))
      setFieldValue('prompt_id', rtnPrompt.id)
      setResetFields(keyVals)
    }
  }, [rtnPrompt?.user_prompt])

  const handleResetFields = () => {
    setFieldValue('prompt_fields', resetFields)
    setShowResetModal(false)
  }

  const handleReorder = (result) => {
    const { source, destination } = result
    const newOrder = values.prompt_fields
    const valueAtSource = newOrder[source.index]
    newOrder[source.index] = newOrder[destination.index]
    newOrder[destination.index] = valueAtSource

    setFieldValue('prompt_fields', newOrder)
    setFieldValue('user_prompt', buildPrompt(values.prompt_fields))
  }

  return (
    <div className="rtn-editor__prompt user-prompt">
      <div className="user-prompt-headline">
        <div>
          <label>2. Edit Prompt</label>
          <p className="muted-text">
            Customize the sections to define key elements you want to identify during calls
          </p>
        </div>
        <div data-testid="reset-button">
          <Button
            type="button"
            onClick={() => {
              setShowResetModal(true)
            }}
            className="restore-defaults-btn"
          >
            Restore Defaults
          </Button>
        </div>
      </div>
      <FieldArray
        name="prompt_fields"
        render={(arrayHelpers) => (
          <DragAndDrop onDragEnd={handleReorder}>
            <Drop droppableId="prompt-fields">
              {values.prompt_fields.map((promptField, index) => {
                const promptFieldWithId = { ...promptField, id: index }
                const isSummary = promptField.key === 'Summary'
                const isSummaryEditable = flags?.summaryEditEnabled === true

                return (
                  <Drag
                    key={promptFieldWithId.id}
                    draggableId={`${promptFieldWithId.id}`}
                    index={index}
                    outer
                    alwaysShowIcon
                    showDragStyle
                  >
                    <div className="rtn-pair">
                      <div className="rtn-key-container">
                        <Field
                          name={`prompt_fields.${index}.key`}
                          component={TextField}
                          className="rtn-pair-key"
                          onKeyUp={(event) => {
                            arrayHelpers.replace(index, {
                              ...promptField,
                              key: event.target.value,
                            })
                            setFieldValue('user_prompt', buildPrompt(values.prompt_fields))
                          }}
                          data-testid={`prompt_fields.${index}.key`}
                          placeholder="Name your call element"
                          disabled={isSummary}
                        />
                        <ConditionalTooltip
                          condition={isSummary}
                          content={
                            isSummaryEditable
                              ? 'You cannot delete the Summary row'
                              : 'You cannot edit or delete the Summary row'
                          }
                          triggerProps={{ style: { display: 'inline-flex' } }}
                        >
                          <Button
                            disabled={isSummary}
                            type="button"
                            basic
                            compact
                            className="svg-button"
                            onClick={() => {
                              arrayHelpers.remove(index)
                              setFieldValue('user_prompt', buildPrompt(values.prompt_fields))
                            }}
                            data-testid={`delete-row-button-${index}`}
                          >
                            <IconTrash className="" data-testid="delete-row-button" />
                          </Button>
                        </ConditionalTooltip>
                      </div>
                      <Field
                        name={`prompt_fields.${index}.value`}
                        component={TextArea}
                        rows="3"
                        className={`rtn-pair-value ${values.prompt_fields[index].value.length > 1099 ? 'error' : ''}`}
                        onKeyUp={(event) => {
                          arrayHelpers.replace(index, {
                            ...promptField,
                            value: event.target.value,
                          })
                          setFieldValue('user_prompt', buildPrompt(values.prompt_fields))
                        }}
                        data-testid={`prompt_fields.${index}.value`}
                        placeholder="Provide clear instructions for the specific call element you want to highlight or analyze"
                        disabled={isSummary && !isSummaryEditable}
                      />
                      {values.prompt_fields[index].value.length > 1099 && (
                        <p className="error">Prompt must be less than 1100 characters</p>
                      )}
                    </div>
                  </Drag>
                )
              })}
              <div className="button-bar">
                <div data-testid="add-row-button" id="add-row-button">
                  <Button
                    type="button"
                    onClick={() => {
                      arrayHelpers.push({ key: '', value: '' })
                    }}
                    primary
                  >
                    Add Instruction
                  </Button>
                </div>
              </div>
            </Drop>
          </DragAndDrop>
        )}
      />

      <BasicModal
        show={showResetModal}
        onClose={() => setShowResetModal(false)}
        title="Are you sure you want to restore defaults?"
        size="tiny"
      >
        {showResetModal && (
          <>
            <div className="mb">
              Restoring defaults will overwrite your current changes. This action cannot be undone.
              Do you want to proceed?
            </div>

            <div className="modal-footer">
              <Button
                secondary
                type="button"
                data-testid="cancel-button"
                onClick={() => setShowResetModal(false)}
              >
                Cancel
              </Button>
              <Button
                primary
                type="button"
                data-testid="confirm-button"
                onClick={handleResetFields}
              >
                Yes, Restore Defaults
              </Button>
            </div>
          </>
        )}
      </BasicModal>
    </div>
  )
}

export default withLDConsumer()(RowEditorForm)
