import React from 'react'
import { Button } from 'semantic-ui-react'
import {
  IconChevronsLeft,
  IconChevronLeft,
  IconChevronsRight,
  IconChevronRight,
} from '@tabler/icons-react'

export const WidgetPagination = ({ olderCursor = null, newerCursor = null, queryDataByCursor }) => {
  return (
    <div
      className="advanced-table__pagination widget-pagination transparent"
      data-testid="widget-pagination"
    >
      <div className="advanced-table__pagination--details" />
      <div className="advanced-table__pagination--arrows">
        <Button
          icon
          secondary
          compact
          data-testid="pagination-first"
          disabled={!newerCursor}
          type="button"
          className="svg-button"
          onClick={() => queryDataByCursor('', 0)}
        >
          <IconChevronsLeft />
        </Button>
        <Button
          icon
          secondary
          compact
          disabled={!newerCursor}
          data-testid="pagination-prev"
          type="button"
          className="svg-button"
          onClick={() => queryDataByCursor(newerCursor, 0)}
        >
          <IconChevronLeft />
        </Button>
        <Button
          icon
          secondary
          compact
          data-testid="pagination-next"
          disabled={!olderCursor}
          type="button"
          className="svg-button"
          onClick={() => queryDataByCursor(olderCursor, 0)}
        >
          <IconChevronRight />
        </Button>
        <Button
          icon
          secondary
          compact
          data-testid="pagination-last"
          disabled={!olderCursor}
          type="button"
          className="svg-button"
          onClick={() => queryDataByCursor('', 1)}
        >
          <IconChevronsRight />
        </Button>
      </div>
    </div>
  )
}
