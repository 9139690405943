import React from 'react'
import { IconPencil, IconTrash } from '@tabler/icons-react'

import { Pill } from '@/components/pills/Pill'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'

export const SavedFiltersList = ({
  savedFilters = [],
  onEditClick,
  onDeleteClick,
  hideEditCreate,
}) => {
  const rows = savedFilters.map((filter) => ({
    id: filter.uuid,
    uuid: filter.uuid,
    name: (
      <div className="flex-align-center small-gap">
        <span>{filter.name}</span>
        {filter.is_default && <Pill small>Default</Pill>}
      </div>
    ),
  }))
  const columns = [{ accessor: 'name', label: 'Name' }]
  const actions = [
    {
      label: 'Edit',
      icon: <IconPencil />,
      fn: (event, row) => {
        onEditClick(row.uuid)
      },
    },
    {
      label: 'Delete',
      icon: <IconTrash />,
      fn: (event, row) => {
        onDeleteClick(row.uuid)
      },
    },
  ].filter((action) => !hideEditCreate || action.label !== 'Edit')

  return (
    <div className="saved-filters-list-wrapper">
      <AdvancedTable
        striped={false}
        sortEnabled={false}
        rows={rows}
        columns={columns}
        actions={actions}
        pagination
        rowsPerPage={10}
      />
    </div>
  )
}
