import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from '@/components/forms/Select'
import { Button } from 'semantic-ui-react'
import { isEmpty } from 'lodash'

import { getSummaryConfigForPlaybook, fetchRTNMetrics } from '@/reducers/playbooks/playbook.actions'
import { fetchPlaybooksByOrg } from '@/reducers/analytics/analytics.actions'
import { setRTNPrompt } from '@/reducers/playbooks/playbook.redux'
import { ConditionalTooltip } from '@/components/ConditionalTooltip'
import { getOrganizationOptions } from '@/utils/helpers'

export const PlaybookForm = ({ setFieldValue, values }) => {
  const dispatch = useDispatch()
  const { organizationid } = useSelector((state) => state.currentUser)
  const analyticsStore = useSelector((state) => state.analytics)
  const { data, loading: analyticsLoading } = analyticsStore
  const { organizations } = useSelector((state) => state)
  const organizationOptions = getOrganizationOptions(organizations)
  const [selectedOrg, setSelectedOrg] = useState(null)
  const isAdmin = organizationid === 1

  useEffect(() => {
    if (!isAdmin) {
      dispatch(fetchPlaybooksByOrg(organizationid))
    }
    dispatch(fetchRTNMetrics())
  }, [])

  const handlePlaybookChange = (playbook) => {
    setFieldValue('selected_playbook_cid', playbook.value)
    if (isAdmin) {
      dispatch(fetchRTNMetrics(selectedOrg, playbook.value))
    } else {
      dispatch(fetchRTNMetrics(null, playbook.value))
    }
  }
  const handlePlaybookLoad = () => {
    dispatch(setRTNPrompt(''))
    dispatch(getSummaryConfigForPlaybook(values.selected_playbook_cid))
  }

  const handleOrgSelect = (dropdownOrg) => {
    setSelectedOrg(dropdownOrg.value)
    dispatch(fetchPlaybooksByOrg(dropdownOrg.value))
    dispatch(fetchRTNMetrics(dropdownOrg.value))
  }

  return (
    <div className="rtn-editor__prompt">
      <label>1. Select a Playbook</label>
      <p className="muted-text">Choose a Playbook to begin editing the AI Notetaker instructions</p>
      <div className="rtn-load-options">
        {organizationid === 1 && (
          <div data-testid="org-dropdown">
            <Select
              options={organizationOptions}
              label="Organization"
              value={selectedOrg}
              onChange={handleOrgSelect}
              placeholder="Select Organization"
              isClearable={false}
            />
          </div>
        )}
        <div data-testid="playbook-dropdown">
          <Select
            loading={analyticsLoading.playbooks}
            options={data.playbooks}
            label="Playbook"
            value={values.selected_playbook_cid}
            onChange={(value) => handlePlaybookChange(value)}
            className="playbook-select"
            isClearable={false}
          />
        </div>
        <ConditionalTooltip
          condition={isEmpty(values.selected_playbook_cid)}
          content="Please select a playbook"
        >
          <div data-testid="start-editing-button">
            <Button
              primary
              type="button"
              onClick={handlePlaybookLoad}
              disabled={isEmpty(values.selected_playbook_cid)}
            >
              Start Editing
            </Button>
          </div>
        </ConditionalTooltip>
      </div>
    </div>
  )
}
