import React, { useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'

import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { authenticateUser } from '@/reducers/auth/currentUser.actions'
import { clearAuthMessage } from '@/reducers/ui/ui.redux'
import { AuthContainer } from './AuthContainer'
import { AuthMessage } from './AuthMessage'

const LoginPageComponent = ({ ldClient }) => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const handleLogin = ({ email, password }, { setSubmitting }) => {
    dispatch(authenticateUser({ email, password, history, location, ldClient }, setSubmitting))
  }

  useEffect(() => {
    dispatch(clearAuthMessage())
    // if we receive search params here it's because it's a login attempt through SSO
    // it needs to have everything that a standard login has
    const { search } = location
    const { token } = queryString.parse(search)

    if (token) {
      dispatch(authenticateUser({ token, history, location, ldClient }))
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Balto Cloud | Login</title>
      </Helmet>

      <AuthContainer>
        <h2 data-testid="login-page">Login</h2>
        <AbstractForm
          schema={[
            { name: 'email', type: 'text', label: 'Email', required: true },
            {
              name: 'password',
              type: 'password',
              inputType: 'password',
              label: 'Password',
              required: true,
            },
          ]}
          onSubmit={handleLogin}
          buttonProps={{ fluid: true }}
          buttonLabel="Login"
        />
        <AuthMessage />
        <Link to="/reset_password" className="link">
          Forgot your password?
        </Link>
      </AuthContainer>
    </>
  )
}

export default withLDConsumer()(LoginPageComponent)
