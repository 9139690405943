import React from 'react'

import { Instructions } from '@/components/forms/CSVUploadForms/Instructions'
import Form from '../../../../components/Modal'
import MultiStepForm from '../../../../components/forms/MultiStepForm'
import Preview from '../../../../components/forms/CSVUploadForms/Preview'
import Receipt from '../../../../components/forms/CSVUploadForms/Receipt'

export const BulkUserUploadButton = ({ tagCategories, setUserFilter }) => {
  return (
    <div style={{ margin: '.5rem' }}>
      <Form
        buttonLabel="Bulk User Upload"
        buttonId="bulk-user-upload"
        header="Bulk User Upload"
        fullScreen
        form={
          <MultiStepForm
            steps={[
              <Instructions tagCategories={tagCategories} />,
              <Preview tagCategories={tagCategories} />,
              <Receipt setUserFilter={setUserFilter} />,
            ]}
          />
        }
      />
    </div>
  )
}
