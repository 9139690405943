import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Button } from 'semantic-ui-react'
import classNames from 'classnames'

import { SearchableDropdown } from '@/components/dropdowns/SearchableDropdown'
import {
  fetchPlaylists,
  addCallsToPlaylist,
  createPlaylist,
  fetchPlaylistsByCallId,
} from '@/reducers/playlists/playlists.actions'
import { BasicModal } from '@/components/layout/modals/BasicModal'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { IconFlag, IconPlaylistAdd } from '@tabler/icons-react'

export const AddToPlaylist = ({
  callIds = [],
  handleDeselectAll,
  organizationId,
  showCoachingPlaylist = true,
  compact = false,
  buttonProps = {},
  ...props
}) => {
  const [newPlaylistModalOpen, setNewPlaylistModalOpen] = useState(false)
  const { playlists, loading } = useSelector((state) => state.playlists)
  const dispatch = useDispatch()
  const { id: callId } = useParams()

  const playlistOptions = playlists
    .filter((playlist) => playlist.type !== 'coaching')
    .map((playlist) => ({
      key: playlist.uuid,
      label: playlist.name,
      value: playlist.uuid,
    }))

  const coachingPlaylist = playlists.find((playlist) => playlist.type === 'coaching')

  const handleOpenNewPlaylistModal = async () => {
    setNewPlaylistModalOpen(true)
  }

  const handleCloseNewPlaylistModal = async () => {
    setNewPlaylistModalOpen(false)
  }

  const handleFlagForCoaching = async () => {
    if (!isEmpty(callIds)) {
      await dispatch(addCallsToPlaylist(coachingPlaylist.uuid, organizationId, callIds, true))

      if (handleDeselectAll) {
        handleDeselectAll()
      }
    }
  }

  const handleAddToPlaylist = async (playlistUuid) => {
    if (playlistUuid && !isEmpty(callIds)) {
      await dispatch(addCallsToPlaylist(playlistUuid, organizationId, callIds, false))

      if (handleDeselectAll) {
        handleDeselectAll()
      }

      if (callId) {
        await dispatch(fetchPlaylistsByCallId(callId))
      }
    }
  }

  const handleCreatePlaylist = async (values) => {
    if (!isEmpty(values.name) && !isEmpty(callIds)) {
      await dispatch(createPlaylist(values.name, organizationId, callIds))

      if (handleDeselectAll) {
        handleDeselectAll()
      }

      if (newPlaylistModalOpen) {
        handleCloseNewPlaylistModal()
      }
    }
  }

  const actions = [
    {
      key: 'new-playlist',
      fn: handleOpenNewPlaylistModal,
      label: (
        <div className="flex-align-center small-gap">
          <IconPlaylistAdd className="icon-svg status-brand" />
          Add to New Playlist
        </div>
      ),
    },
  ]

  useEffect(() => {
    if (organizationId) {
      dispatch(fetchPlaylists(organizationId, true))
    }
  }, [])

  return (
    <>
      <div className="flex-align-center medium-gap">
        {showCoachingPlaylist && (
          <Button
            data-testid="coaching-playlist"
            icon
            disabled={loading.playlists}
            loading={loading.playlists}
            secondary
            className="svg-button"
            compact={compact}
            onClick={handleFlagForCoaching}
            {...props}
          >
            <IconFlag className="status-warning" />
            Flag for Coaching
          </Button>
        )}

        <SearchableDropdown
          buttonProps={{
            content: 'Add to Playlist',
            className: classNames('secondary', compact),
            ...buttonProps,
          }}
          inputProps={{ placeholder: 'Search...' }}
          options={playlistOptions}
          loading={loading.playlists}
          menuPosition="right"
          handleSelect={handleAddToPlaylist}
          actions={actions}
          dataTestId="playlist"
          {...props}
        />
      </div>

      {newPlaylistModalOpen && (
        <BasicModal
          data-testid="add-to-new-playlist"
          title="New Playlist"
          onClose={handleCloseNewPlaylistModal}
          show={newPlaylistModalOpen}
          size="tiny"
        >
          <AbstractForm
            schema={[
              {
                name: 'name',
                type: 'text',
                label: 'Playlist Name',
                required: true,
                autoFocus: true,
              },
            ]}
            onSubmit={handleCreatePlaylist}
            buttonLabel="Create Playlist with Calls"
            onClose={handleCloseNewPlaylistModal}
            isModal
          />
        </BasicModal>
      )}
    </>
  )
}
