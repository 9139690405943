import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'

import { ModuleCard } from '@/components/cards/ModuleCard'
import { EChart } from '@/components/charts/EChart'

import { getBarChartData } from '../helpers/metrics.helpers'

export const VanityMetrics = () => {
  const { rtnMetrics = {}, loading } = useSelector((state) => state.playbook)
  const {
    total_summaries: totalSummaries,
    total_summaries_copied: totalSummariesCopied,
    summaries_by_date: summariesByDate = [],
  } = rtnMetrics

  const barChartData = useMemo(() => getBarChartData(summariesByDate), [rtnMetrics])

  const renderLoader = () => {
    return <Loader active inline size="large" data-testid="loader" />
  }

  return (
    <div className="flex-space-between gap">
      <ModuleCard
        className="full-width vanity-metrics"
        title="Notes Generated"
        data-testid="notes-generated"
      >
        <div className="flex-column">
          {loading.rtnMetrics ? renderLoader() : <h1>{totalSummaries}</h1>}
          <p>Last 30 Days</p>
        </div>
      </ModuleCard>
      <ModuleCard
        className="full-width vanity-metrics"
        title="Notes Copied by Agent"
        data-testid="notes-copied"
      >
        <div className="flex-column">
          {loading.rtnMetrics ? renderLoader() : <h1>{totalSummariesCopied}</h1>}
          <p>Last 30 Days</p>
        </div>
      </ModuleCard>
      <ModuleCard
        className="full-width vanity-metrics"
        title="Notes Generated by Day"
        data-testid="notes-generated-bar-chart"
      >
        {loading.rtnMetrics ? (
          renderLoader()
        ) : (
          <EChart style={{ height: '45px' }} option={barChartData} />
        )}
        <p style={{ marginTop: 5 }}>Last 30 Days</p>
      </ModuleCard>
    </div>
  )
}
