import React from 'react'
import { Popup } from 'semantic-ui-react'

import { UserInitial } from '@/components/UserInitial'

export const MyTeamAgentList = ({ agents }) => {
  return (
    <Popup
      inverted
      content={
        <div className="text-left">
          {agents.map((filter) => (
            <div key={filter.label}>{filter.label}</div>
          ))}
        </div>
      }
      trigger={
        <div>
          {agents
            .filter((filter, index) => index < 3)
            .map((filter, index) => (
              <UserInitial userFullName={filter.label} index={index} inline key={filter.label} />
            ))}
          {agents.length > 3 && <UserInitial overflowCount={agents.length - 3} inline />}
        </div>
      }
    />
  )
}
