import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'

import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { AddToPlaylist } from '@/views/Playlists/components/AddToPlaylist'

export const CallSearchTable = ({
  columns,
  filters,
  calls,
  loading,
  handleClearFilters,
  updateRowIdsRendered,
  organizationId,
  dataGridAdditionalHeaderOffset,
}) => {
  const [selectedRows, setSelectedRows] = useState([])

  const handleBulkSelectSingle = (row) => {
    if (!selectedRows.includes(row)) {
      setSelectedRows([...selectedRows, row])
    } else {
      setSelectedRows(selectedRows.filter((selectedRow) => selectedRow !== row))
    }
  }

  const handleSelectAll = (rows) => {
    setSelectedRows(rows.map((row) => row.call_id))
  }

  const handleDeselectAll = () => {
    setSelectedRows([])
  }

  useEffect(() => {
    // Deselect all filters if loading, because that means a new call search is being performed
    if (loading.calls) {
      handleDeselectAll()
    }
  }, [loading])

  return (
    <AdvancedTable
      dataGrid
      dataGridAdditionalHeaderOffset={dataGridAdditionalHeaderOffset}
      columns={columns}
      rows={calls}
      loading={loading.calls}
      noDataHeader="No calls match the search criteria"
      noDataText="Try increasing the date range or modifying your current filters."
      noDataAction={
        !!filters.organizationId && (
          <Button type="button" primary onClick={handleClearFilters}>
            Clear All Filters
          </Button>
        )
      }
      rowsPerPage={30}
      striped={false}
      borderless
      pagination
      index="call_id"
      defaultOrder="desc"
      defaultOrderBy="start_time"
      bulkSelectable
      selectedRows={selectedRows}
      handleBulkSelectSingle={handleBulkSelectSingle}
      handleDeselectAll={handleDeselectAll}
      bulkSelectAction={
        organizationId && (
          <div className="flex-align-center medium-gap">
            <AddToPlaylist
              organizationId={organizationId}
              callIds={selectedRows}
              handleDeselectAll={handleDeselectAll}
              compact
              buttonProps={{
                content: `Add to Playlist`,
                className: 'secondary compact',
              }}
            />
          </div>
        )
      }
      handleSelectAll={handleSelectAll}
      updateRowIdsRendered={updateRowIdsRendered}
    />
  )
}
