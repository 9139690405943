import React, { useEffect, useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useParams, useLocation } from 'react-router-dom'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'

import {
  applyFilters,
  setSavedFilterById,
  clearSavedFilter,
  loadInitialData,
} from '@/reducers/analytics/analytics.actions'
import { ANALYTICS_FILTER_TYPE } from '@/reducers/savedFilters/savedFilters.constants'

import { AnalyticsFilters } from './AnalyticsFilters'
import { OverviewSection } from './sections/OverviewSection'
import { DynamicPromptSection } from './sections/DynamicPromptSection'
import { ChecklistSection } from './sections/ChecklistSection'
import { NotificationsSection } from './sections/NotificationsSection'
import { PostCallSection } from './sections/PostCallSection'
import { UsageSection } from './sections/UsageSection'
import { ExportCSVSection } from './sections/ExportCSVSection'
import { SavedFilters } from '../../components/savedFilters/SavedFilters'

import './AnalyticsPage.scss'

const sectionData = [
  { route: 'checklist', title: 'Checklist', component: ChecklistSection },
  { route: 'dynamic-prompt', title: 'Dynamic Prompt', component: DynamicPromptSection },
  { route: 'notifications', title: 'Notifications', component: NotificationsSection },
  { route: 'postcall', title: 'Post Call', component: PostCallSection },
  { route: 'usage', title: 'Usage', component: UsageSection },
  { route: 'csv', title: 'Export CSV', component: ExportCSVSection },
]

function getPageTitle(section) {
  if (section) {
    return sectionData.find((data) => data.route === section).title
  }

  return 'Overview'
}

function renderSection(section) {
  if (section) {
    const Component = sectionData.find((data) => data.route === section).component

    return <Component />
  }

  return <OverviewSection />
}

const AnalyticsPage = () => {
  const dispatch = useDispatch()
  const { section } = useParams()
  const location = useLocation()
  const { customBranding } = useSelector((state) => state.customBranding)
  const {
    initialLoadComplete,
    data: { selectedSavedFilterId },
  } = useSelector((state) => state.analytics)
  const {
    organizationid: currentUserOrgId,
    own_organization_id: ownOrganizationId,
    hierarchy_manager: isHierarchyManager,
  } = useSelector((state) => state.currentUser)
  const childOrgSelected = isHierarchyManager && currentUserOrgId !== ownOrganizationId
  const pageTitle = customBranding?.customBrandingDisplayName || 'Balto Cloud'
  const originalSection = section === 'dynamic-prompt' ? 'deck' : section

  const handleSelectSavedFilter = (option) => {
    if (!option) {
      dispatch(clearSavedFilter())
    } else {
      const savedFilterId = option.value
      dispatch(setSavedFilterById(savedFilterId, true, originalSection))
    }
  }

  useEffect(() => {
    // Load all data on initial mount - this is needed because /reports and /reports/{section}
    // always count as separate routes, so it will keep trying to fetch unnecessary extra data if
    // we don't check for initialLoadComplete
    if (!initialLoadComplete) {
      dispatch(loadInitialData())
    } else {
      // All data is loaded or we've changed routes. Re-fetch all the data
      dispatch(applyFilters(section))
    }
  }, [initialLoadComplete, section])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  // reroute deck section to new dynamic prompt url
  if (section === 'deck') {
    return <Redirect to="/reports/dynamic-prompt" />
  }

  return (
    <>
      <Helmet>
        <title>{`${pageTitle} | Reporting: ${getPageTitle(section)}`}</title>
      </Helmet>

      <div className="header-wrapper">
        <h1 data-testid="analytics-page">{getPageTitle(section)}</h1>
        {!childOrgSelected && (
          <SavedFilters
            filterType={ANALYTICS_FILTER_TYPE}
            selectedSavedFilterId={selectedSavedFilterId}
            handleSelectSavedFilter={handleSelectSavedFilter}
          />
        )}
      </div>
      <AnalyticsFilters />

      {renderSection(section)}
    </>
  )
}

export default withLDConsumer()(AnalyticsPage)
