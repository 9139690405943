import React from 'react'
import { useDispatch } from 'react-redux'
import { isArray, isNull } from 'lodash'
import * as Yup from 'yup'

import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { closeModal } from '@/reducers/ui/ui.redux'

import Form from '@/components/Modal'
import ConfirmForm from '@/components/forms/ConfirmForm'

const getFormattedAuthIPs = (desktopAuthIPs) => {
  if (isArray(desktopAuthIPs)) {
    return desktopAuthIPs.join(',')
  }

  if (isNull) {
    return ''
  }

  return desktopAuthIPs
}

export const AuthorizationOptions = ({
  desktopAuthKey,
  desktopAuthIPs,
  organizationUuid,
  samlMetadataUrl,
  deleteDesktopTokens,
  handleUpdateDesktopAuthIPs,
  handleUpdateDesktopAuthKey,
  handleUpdateSSO,
}) => {
  const dispatch = useDispatch()
  const formattedAuthIPs = getFormattedAuthIPs(desktopAuthIPs)

  return (
    <>
      <h3>Authorization</h3>

      <div className="ui horizontal segments organization-buttons-bar">
        <div className="ui segment">
          <h3>Manage Desktop Key</h3>

          <ButtonAndFormModal
            buttonLabel="Manage Key"
            modalTitle="Manage Desktop Key"
            modalId="organizations/manageDesktopKey"
            modalProps={{ size: 'tiny' }}
            buttonProps={{ secondary: true }}
            form={
              <AbstractForm
                schema={[
                  {
                    name: 'desktop_auth_key',
                    type: 'text',
                    label: 'Desktop Key',
                    placeholder: 'Enter desktop key',
                    customValidators: [
                      Yup.string()
                        .required('Desktop Key is required')
                        .min(9, 'Must be at least nine characters long'),
                    ],
                  },
                  {
                    name: 'confirm_checkbox',
                    type: 'checkbox',
                    label: 'I want to make changes',
                    required: true,
                  },
                ]}
                existingValues={{ desktop_auth_key: desktopAuthKey || '' }}
                onSubmit={handleUpdateDesktopAuthKey}
                buttonLabel="Save"
                onClose={() => dispatch(closeModal())}
                isModal
              />
            }
          />
        </div>
        <div className="ui segment">
          <h3>Manage Authorized IPs</h3>
          <ButtonAndFormModal
            buttonLabel="Manage IPs"
            modalTitle="Authorized IPs"
            modalContent={
              <p>
                Enter IP addresses in the format <code>0.0.0.0</code>, separated by{' '}
                <strong>commas</strong>.
              </p>
            }
            modalId="organizations/manageIPs"
            modalProps={{ size: 'tiny' }}
            buttonProps={{ secondary: true }}
            form={
              <AbstractForm
                schema={[
                  {
                    name: 'desktop_auth_ips',
                    type: 'textArea',
                    label: 'Authorized IPs',
                    placeholder: 'Enter IP addresses in the format 0.0.0.0, separated by commas',
                  },
                  {
                    name: 'confirm_checkbox',
                    type: 'checkbox',
                    label: 'I want to make changes',
                    required: true,
                  },
                ]}
                existingValues={{ desktop_auth_ips: formattedAuthIPs }}
                onSubmit={handleUpdateDesktopAuthIPs}
                buttonLabel="Save"
                onClose={() => dispatch(closeModal())}
                isModal
              />
            }
          />
        </div>

        <div className="ui segment">
          <h3>Delete All Desktop Tokens</h3>
          <Form
            buttonProps={{ secondary: true }}
            header="Delete All Tokens"
            buttonLabel="Delete Tokens"
            buttonId="delete-tokens"
            modalSize="tiny"
            form={
              <ConfirmForm
                bodyText="Are you sure you want to delete all user desktop tokens? Every user will be required to authenticate with a desktop key or IP address."
                primaryButtonText="Delete"
                handleSubmit={deleteDesktopTokens}
                danger
              />
            }
          />
        </div>

        <div className="ui segment">
          <h3>Manage SSO (SAML)</h3>
          <ButtonAndFormModal
            buttonLabel="Manage SSO"
            modalTitle="Manage SSO (SAML)"
            modalContent={
              <>
                <p>
                  NOTE: legacy integrations may have entity ID `baltosoftware` and ACS URI with path
                  `saml/sso` (instead of `saml/sso/acs`). That should work, but if they are having
                  issues please have them update to use the new values.
                </p>
                <p>
                  <strong>Audience/EntityID</strong>
                  <br />
                  {`https://baltocloud.com/sp/${organizationUuid}`}
                </p>
                <p>
                  <strong>Recipient/ACS URI</strong>
                  <br />
                  {`${window.location.origin}/saml/sso/acs/${organizationUuid}`}
                </p>
              </>
            }
            modalId="organizations/manageSSO"
            modalProps={{ size: 'small' }}
            buttonProps={{ secondary: true }}
            form={
              <AbstractForm
                schema={[
                  {
                    name: 'saml_metadata_url',
                    type: 'text',
                    label: 'SAML Metadata URL',
                    placeholder: 'https://sso-provider.com/metadata.xml',
                  },
                  {
                    name: 'confirm_checkbox',
                    type: 'checkbox',
                    label: 'I want to make changes',
                    required: true,
                  },
                ]}
                existingValues={{ saml_metadata_url: samlMetadataUrl }}
                onSubmit={handleUpdateSSO}
                buttonLabel="Save"
                onClose={() => dispatch(closeModal())}
                isModal
              />
            }
          />
        </div>
      </div>
    </>
  )
}
