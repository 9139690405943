import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'

import { closeModal } from '@/reducers/ui/ui.redux'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import { MY_TEAM_FILTER_TYPE } from '@/reducers/savedFilters/savedFilters.constants'
import { formatSavedFilterFormExistingValues } from '@/reducers/savedFilters/savedFilters.helpers'
import {
  saveFilter,
  updateSavedFilter,
  fetchFilterFormData,
} from '@/reducers/savedFilters/savedFilters.actions'

import { buildSchema } from './SavedFilterForm.schema'

export const SavedFilterForm = ({ selectedFilter, filterFormOptions, filterType }) => {
  const dispatch = useDispatch()
  const { data, loading } = useSelector((state) => state.savedFilters)
  const { organizationid: currentUserOrgId } = useSelector((state) => state.currentUser)
  const savedFilterFormDefaultValues = {
    isDefault: false,
    filterType,
    ...(filterType === MY_TEAM_FILTER_TYPE && { name: 'My Team' }),
  }

  const existingValues = selectedFilter
    ? formatSavedFilterFormExistingValues(selectedFilter, filterType)
    : savedFilterFormDefaultValues

  const handleClose = () => {
    dispatch(closeModal())
  }

  const handleSubmit = async (filter, { setSubmitting }) => {
    if (selectedFilter) {
      await dispatch(updateSavedFilter(selectedFilter.uuid, filter, filterType))
    } else {
      await dispatch(saveFilter(filter, filterType))
    }

    setSubmitting(false)
  }

  const schema = buildSchema(data, loading, filterFormOptions)

  useEffect(() => {
    dispatch(fetchFilterFormData(currentUserOrgId, filterFormOptions))
  }, [])

  if (loading[filterType]) {
    return <Loader active />
  }

  return (
    <AbstractForm
      onSubmit={handleSubmit}
      existingValues={existingValues}
      schema={schema}
      buttonLabel="Save"
      onClose={handleClose}
      isModal
    />
  )
}
