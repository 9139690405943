import React, { useMemo, useRef, useEffect } from 'react'
import { init, getInstanceByDom } from 'echarts'
import { debounce } from 'lodash'

/**
 * Create a div that injects the echarts canvas via ref
 * @url https://echarts.apache.org/en/index.html
 * @example https://echarts.apache.org/examples/en/index.html
 *
 * @param {Object}  option  contains all the configuration for any chart. See examples for more info
 * @param {Object}  style   override CSS for the div
 * @param {Boolean} loading toggle loading overlay on the chart
 * @param {Object}  events  event listeners for the chart in the form of { event: handlerFn }
 *
 * @returns HTMLElement<div>
 */
export const EChart = ({ option, style = {}, loading = false, events = {}, ...props }) => {
  const chartSettings = { useCoarsePointer: true } // enables clicking near a line and still highlighting it
  const optionSettings = { notMerge: true } // don't merge two options together when updating option
  const chartRef = useRef(null)

  // Debounce resize event so it only fires periodically instead of constantly
  const resizeChart = useMemo(
    () =>
      debounce(() => {
        if (chartRef.current) {
          const chart = getInstanceByDom(chartRef.current)
          // resize: https://echarts.apache.org/en/api.html#echartsInstance.resize
          chart.resize()
        }
      }, 50),
    []
  )

  useEffect(() => {
    // Initialize chart
    let chart
    if (chartRef.current) {
      // init: https://echarts.apache.org/en/api.html#echarts.init
      chart = init(chartRef.current, null, chartSettings)

      // Set up event listeners
      for (const [key, handler] of Object.entries(events)) {
        chart.on(key, (param) => {
          handler(param)
        })
      }
    }

    // Resize event listener
    const resizeObserver = new ResizeObserver(() => {
      resizeChart()
    })

    resizeObserver.observe(chartRef.current)

    // Return cleanup function
    return () => {
      // dispose: https://echarts.apache.org/en/api.html#echartsInstance.dispose
      chart?.dispose()
      if (chartRef.current) {
        resizeObserver.unobserve(chartRef.current)
      }
      resizeObserver.disconnect()
    }
  }, [])

  useEffect(() => {
    // Re-render chart when option changes
    if (chartRef.current) {
      const chart = getInstanceByDom(chartRef.current)
      // setOption: https://echarts.apache.org/en/api.html#echartsInstance.setOption
      chart.setOption(option, optionSettings)
    }
  }, [option])

  useEffect(() => {
    // Toggle loading state - may remove in favor of Semantic
    if (chartRef.current) {
      // getInstanceByDom: https://echarts.apache.org/en/api.html#echarts.getInstanceByDom
      const chart = getInstanceByDom(chartRef.current)

      if (loading) {
        // loading: https://echarts.apache.org/en/api.html#echartsInstance.showLoading
        chart.showLoading()
      } else {
        chart.hideLoading()
      }
    }
  }, [loading])

  return (
    <div
      data-testid="echart"
      ref={chartRef}
      style={{ width: '100%', height: '350px', ...style }}
      {...props}
    />
  )
}
