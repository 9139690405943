import React from 'react'
import { Button } from 'semantic-ui-react'
import pluralize from 'pluralize'

export const BulkSelectBanner = ({
  handleBulkDeselect,
  handleSelectAll,
  selectedRows,
  searchFilteredRows,
  rows,
  rowsPerPage,
  bulkSelectAction,
}) => {
  const allRowsSelected = selectedRows.length === rows.length
  // We're using "call" as the noun here, but if this is going to be used for other tables, we need to pass in the noun as a prop.
  return (
    <div className="bulk-select-banner">
      <div className="flex-align-center medium-gap">
        <div>
          {allRowsSelected
            ? selectedRows.length === 1
              ? 'All calls selected.'
              : `All ${selectedRows.length} calls selected.`
            : `${selectedRows.length} ${pluralize('call', selectedRows.length)} selected.`}

          {allRowsSelected && (
            <Button
              data-testid="deselect-all-button"
              className="inline-link-button"
              onClick={handleBulkDeselect}
            >
              Deselect all calls.
            </Button>
          )}

          {/* Theoretically, the selected rows might be the same length as the filteed rows if they do some shenanigans
        like selecting calls before filtering, then deselecting some calls after filtering, but it's unlikely enough that
        I didn't think it was necessary here to check all the call IDs in both lists and ensure they're the same. If something comes up,
        we can fix it.
         */}
          {!allRowsSelected && searchFilteredRows.length !== selectedRows.length && (
            <Button
              data-testid="select-all-button"
              className="inline-link-button"
              onClick={handleSelectAll}
            >
              {rows.length === searchFilteredRows.length
                ? `Select all ${searchFilteredRows.length} calls across all pages.`
                : `Select matching ${searchFilteredRows.length} calls${rowsPerPage < searchFilteredRows.length ? ' across all pages.' : '.'}`}
            </Button>
          )}
        </div>
      </div>
      {bulkSelectAction}
    </div>
  )
}
